import { useState, useCallback, useEffect, useReducer } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import { Tooltip } from 'react-tooltip'
import { EditorContentProvider } from '../providers/EditorContentProvider'
import { SidebarProvider } from '../providers/SidebarProvider'
import { useSubscriptionVerification } from '../hooks/useSubscriptionVerification'
import { useUserState } from '../providers/UserProvider'
import { useSelectedRecordName } from '../providers/SelectedRecordNameProvider'
import useBulletAndTaskItemCharacters from '../hooks/useBulletAndTaskItemCharacters'
import { MainSidebar } from './sidebar/MainSidebar'
import { CalendarWrapper } from './calendar/CalendarWrapper'
import CommandBar from './command-bar/CommandBar'
import NoteEditor from './editor/NoteEditor'
import { TrialBanner } from './payments/TrialBanner'
import { CheckoutDialog } from './payments/CheckoutDialog'
import { Search } from './search/Search'
import { ReferencesProvider } from '../providers/ReferencesProvider'
import {
  CommandBarState,
  CommandBarAction,
  CommandBarOptionEvent,
} from './command-bar/types'

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions -- doesn't work with 'type'
  interface Window {
    Beacon: (action: string, data: unknown) => void
  }
}

function arraysEqual(a: string[], b: string[]) {
  if (a.length !== b.length) return false
  const uniqueValuesA = new Set(a)
  const uniqueValuesB = new Set(b)
  if (uniqueValuesA.size !== uniqueValuesB.size) return false
  for (const value of uniqueValuesA) {
    if (!uniqueValuesB.has(value)) return false
  }
  return true
}

export function Layout() {
  const {
    isTrialLoading,
    setDialogOpen: setPaywallOpen,
    dialogOpen: paywallOpen,
    trialEndDate,
    isSubscribed,
    isGuest,
    isTrialRunning,
    trackTrialBannerClicked,
    stripeCustomerId,
  } = useSubscriptionVerification()

  const user = useUserState()
  window.Beacon('identify', {
    email: user?.email,
  })

  const { taskItemCharacters, bulletItemCharacters } =
    useBulletAndTaskItemCharacters()
  const [dialogShown, setDialogShown] = useState(false)

  const selectedRecordName = useSelectedRecordName()

  // #region command bar
  const [commandBarState, dispatch] = useReducer(
    (state: CommandBarState, action: CommandBarAction): CommandBarState => {
      switch (action.type) {
        case 'hide': {
          return { visible: false }
        }
        case 'showSearch': {
          return {
            visible: true,
            mode: 'search',
            searchTerm: action.searchTerm ?? '',
          }
        }
        case 'showOptions': {
          return {
            visible: true,
            mode: 'options',
            options: action.options,
            searchTerm: action.searchTerm ?? '',
            placeholder: action.placeholder,
          }
        }
        case 'updateSearch': {
          if (!state.visible) return state
          return {
            ...state,
            searchTerm: action.searchTerm,
          }
        }
      }
    },
    { visible: false }
  )

  // Listen for command bar events
  useEffect(() => {
    const handleShowOptions = (event: CommandBarOptionEvent) => {
      const { options, placeholder, searchText } = event.detail
      dispatch({
        type: 'showOptions',
        options,
        searchTerm: searchText,
        placeholder,
      })
    }

    window.addEventListener(
      'showCommandBarOptions',
      handleShowOptions as EventListener
    )
    return () => {
      window.removeEventListener(
        'showCommandBarOptions',
        handleShowOptions as EventListener
      )
    }
  }, [])
  // #endregion

  // #region calendar
  const [showCalendar, setShowCalendar] = useLocalStorage('showCalendar', true)

  const toggleCalendar = useCallback(() => {
    setShowCalendar(!showCalendar)
  }, [setShowCalendar, showCalendar])
  // #endregion

  // #region search
  const [searchQuery, setSearchQuery] = useState('') //useLocalStorage('searchQuery', '')

  const handleSearch = useCallback(
    (query: string) => {
      setSearchQuery(query)
    },
    [setSearchQuery]
  )

  // When the selectedRecordName changes and starts with 'search', try to split it by :::: to see if it comes with a query (we use this when clicking on tags or mentions)
  useEffect(() => {
    if (selectedRecordName.startsWith('search')) {
      const [, query] = selectedRecordName.split('::::')
      handleSearch(query || '')
    }
  }, [selectedRecordName, handleSearch])

  // #endregion

  // Show a warning dialog when the user is signed in CloudKit and Supabase and has non-default bullet and task characters
  useEffect(() => {
    const defaultBulletItemCharacters = ['-']
    const defaultTaskItemCharacters = ['*']
    if (
      user?.cloudKitUserId &&
      user.supabaseUserId &&
      !arraysEqual(taskItemCharacters, defaultTaskItemCharacters) &&
      !arraysEqual(bulletItemCharacters, defaultBulletItemCharacters) &&
      !dialogShown
    ) {
      setDialogShown(true)
      // eslint-disable-next-line no-alert -- quick fix
      alert(
        'You have non-default bullet and task characters set on your Mac app. This can lead to issues when editing teamspace notes.'
      )
    }
  }, [user, taskItemCharacters, bulletItemCharacters, dialogShown])

  return (
    <div className='flex h-screen max-h-screen flex-col'>
      <div className='flex flex-grow flex-col'>
        {!isSubscribed && !isTrialLoading && (
          <div className='flex-shrink-0'>
            <TrialBanner
              isTrialRunning={isTrialRunning}
              trialEndDate={trialEndDate ?? ''}
              isGuest={isGuest}
              onClick={() => {
                trackTrialBannerClicked()
                setPaywallOpen(true)
              }}
            />
          </div>
        )}
        <SidebarProvider
          onOpenCommandBar={(search) => {
            dispatch({ type: 'showSearch', searchTerm: search })
          }}
          openPaywall={() => {
            setPaywallOpen(true)
          }}
          isGuest={isGuest}
          isExpired={isTrialRunning === 'expired' && !isSubscribed}
        >
          <div className='flex flex-grow flex-col-reverse md:flex-row'>
            {user ? (
              <CheckoutDialog
                setOpen={setPaywallOpen}
                open={paywallOpen}
                isGuest={isGuest}
                stripeCustomerId={stripeCustomerId}
              />
            ) : undefined}
            <MainSidebar />
            <ReferencesProvider>
              <EditorContentProvider>
                {selectedRecordName.startsWith('search') ? (
                  <Search query={searchQuery} onSearch={handleSearch} />
                ) : (
                  <NoteEditor
                    showCalendar={showCalendar}
                    toggleCalendar={toggleCalendar}
                    onCommandBarOpen={(search) => {
                      dispatch({ type: 'showSearch', searchTerm: search })
                    }}
                    isSubscribed={isSubscribed}
                    isGuest={isGuest}
                    isTrialRunning={isTrialRunning}
                    isTrialLoading={isTrialLoading}
                    setDialogOpen={setPaywallOpen}
                  />
                )}
                <CalendarWrapper
                  showCalendar={showCalendar}
                  toggleCalendar={toggleCalendar}
                />
              </EditorContentProvider>
            </ReferencesProvider>

            <CommandBar state={commandBarState} dispatch={dispatch} />

            <Tooltip
              id='my-tooltip'
              className='tooltip'
              disableStyleInjection
            />
          </div>
        </SidebarProvider>
      </div>
    </div>
  )
}
