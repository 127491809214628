import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useQueryClient, type UseMutateFunction } from '@tanstack/react-query'
import dayjs from 'dayjs'
import {
  type Note,
  NoteType,
  SourceDatabase,
  filenameToDate,
  isCalendarNote,
  isFolder,
  isTeamspaceNote,
} from '../utils/syncUtils'
import { useCreateNote, type CreateOptions } from '../hooks/useCreateNote'
import {
  type SidebarEntry,
  createSidebarEntries,
  exportNotes,
  findNoteKey,
} from '../modules/sidebar/SidebarBuilder'
import { type useNoteConfig } from '../hooks/useNote'
import { cacheKeys } from '../utils/queryKeyFactory'
import useStarterNotes from '../hooks/useStarterNotes'
import { useManageSubscription } from '../hooks/useManageSubscription'
import { trackEvent } from '../lib/analytics'
import {
  useSelectedRecordName,
  useSelectedRecordNameDispatch,
} from './SelectedRecordNameProvider'
import {
  useSelectedDate,
  useSelectedDateDispatch,
} from './SelectedDateProvider'
import { useAuthenticatedUser } from './UserProvider'
import { CacheData, cachedNotesContext } from './CachedNotesProvider'
import {
  usePrivateCalendarNotes,
  usePrivateProjectNotes,
  usePrivateProjectNotesByTitle,
  useTeamCalendarNotes,
  useTeamProjectNotes,
} from '../hooks/useNotesFactory'
import { useNotification } from './NotificationProvider'
import { errorIcon } from '../components/Notification'
import { v4 as uuid } from 'uuid'

type SidebarProviderContextType = {
  noteKey: useNoteConfig
  handleExportSupabaseTeamspace: (recordName: string) => void
  handleSelectRecordName: (recordName: string) => void
  handleRevealNote: (recordName?: string, range?: Range) => void
  privateProjectNotes: Map<string, Note> | undefined
  privateCalendarNotes: Map<string, Note> | undefined
  privateNotes: Map<string, Note>
  teamProjectNotes: Map<string, Note> | undefined
  teamCalendarNotes: Map<string, Note> | undefined
  teamNotes: Map<string, Note>
  notes: Map<string, Note>
  sidebarEntries: SidebarEntry[]
  createNote: UseMutateFunction<Note, Error, CreateOptions, CacheData>
  breadcrumb: string[]
  setBreadcrumb: React.Dispatch<React.SetStateAction<string[]>>
  shouldForceUpdateEditor: number
}

export const SidebarContext = createContext<
  SidebarProviderContextType | undefined
>(undefined)

export function useSidebarProvider() {
  const context = useContext(SidebarContext)
  if (context === undefined) {
    throw new Error('useSidebarProvider must be used within a SidebarProvider')
  }
  return context
}

type Props = {
  onOpenCommandBar: (search: string) => void
  openPaywall: () => void
  isGuest: boolean
  isExpired: boolean
  children: ReactNode
}

export function SidebarProvider({
  onOpenCommandBar,
  openPaywall,
  isGuest,
  isExpired,
  children,
}: Props) {
  const selectedRecordName = useSelectedRecordName()
  const setSelectedRecordName = useSelectedRecordNameDispatch()
  const { createTeamspaceStarterNotes } = useStarterNotes()
  const user = useAuthenticatedUser()
  const { isLoading: isLoadingPrivateProjectNotes, data: privateProjectNotes } =
    usePrivateProjectNotes()
  const { isLoading: isLoadingTeamProjectNotes, data: teamProjectNotes } =
    useTeamProjectNotes()
  const { data: privateCalendarNotes } = usePrivateCalendarNotes()
  const privateNotes = useMemo(() => {
    return new Map<string, Note>([
      ...(privateProjectNotes?.map ?? []),
      ...(privateCalendarNotes?.map ?? []),
    ])
  }, [privateProjectNotes, privateCalendarNotes])

  const { data: teamCalendarNotes } = useTeamCalendarNotes()
  const teamNotes = useMemo(() => {
    return new Map<string, Note>([
      ...(teamProjectNotes?.map ?? []),
      ...(teamCalendarNotes?.map ?? []),
    ])
  }, [teamProjectNotes, teamCalendarNotes])
  const notes = useMemo(
    () => new Map<string, Note>([...privateNotes, ...teamNotes]),
    [privateNotes, teamNotes]
  )
  const [needsAttention, setNeedsAttention] = useState(false)
  const [shouldForceUpdateEditor, setShouldForceUpdateEditor] = useState(0)
  const selectedDate = useSelectedDate()
  const selectedDateDispatch = useSelectedDateDispatch()
  const [breadcrumb, setBreadcrumb] = useState<string[]>([])
  const { showNotification, hideNotification } = useNotification()

  const handleRevealNote = useCallback(
    (recordName?: string, _range?: Range) => {
      if (!recordName) return
      const note = notes.get(recordName)
      setNeedsAttention(true)
      if (note) {
        if (isCalendarNote(note.noteType)) {
          const { date, timeframe } = filenameToDate(note.filename)
          if (date == null) {
            const errorDetails = {
              message: 'Error: Invalid note filename',
              description: "Calendar note doesn't contain a valid date",
              filename: note.filename,
              noteType: note.noteType,
            }

            showNotification({
              title: errorDetails.message,
              message: errorDetails.description,
              icon: errorIcon,
            })

            trackEvent(
              'WEB - SidebarProvider.handleRevealNote.error',
              errorDetails
            )
          } else {
            if (timeframe === 'day') {
              if (note.parent) {
                setSelectedRecordName(`daily_${note.parent}`)
              }
              selectedDateDispatch({ type: 'setDay', date })
            }
            if (timeframe === 'week') {
              if (note.parent) {
                setSelectedRecordName(`weekly_${note.parent}`)
              }
              selectedDateDispatch({
                type: 'setWeek',
                week: date.week(),
                year: date.year(),
              })
            }
          }
        } else {
          setSelectedRecordName(recordName)
        }

        const buildBreadcrumb = (recordName: string): string[] => {
          const crumbs: string[] = []
          const note = notes.get(recordName)
          if (note) {
            if (note.source === SourceDatabase.SUPABASE) {
              const parent = note.parent ? notes.get(note.parent) : undefined
              if (parent?.recordName) {
                crumbs.push(
                  ...buildBreadcrumb(parent.recordName),
                  parent.recordName
                )
              }
            } else {
              // Iteratively split the path of the note.filename. It's like folder1/folder2/folder3/filename.md, we need to add folder1/folder2/folder3, folder1/folder2, folder1
              const path = note.filename.split('/')
              for (let i = 0; i < path.length - 1; i++) {
                crumbs.push(path.slice(0, i + 1).join('/'))
              }
            }
          }

          // Add the root as well ('teamspaces' or 'private notes')
          if (note?.noteType) {
            crumbs.push(isTeamspaceNote(note.noteType) ? 'teamspaces' : 'notes')
          }

          return crumbs
        }

        setBreadcrumb([note.filename, ...buildBreadcrumb(selectedRecordName)])

        // EDIT: The selection is always off, comment it out for now, until we figured out a better way of highlighting the selected reference, maybe some attribute
        // setTextSelection({ from: range?.from ?? 0, to: range?.to ?? 0 })
      }
    },
    [
      notes,
      selectedRecordName,
      showNotification,
      selectedDateDispatch,
      setSelectedRecordName,
    ]
  )

  const { createPrivateStarterNotes: createStarterNotes } = useStarterNotes()
  const [isCreatingStarterNotes, setIsCreatingStarterNotes] = useState(false)

  useEffect(() => {
    if (!isCreatingStarterNotes) {
      setIsCreatingStarterNotes(true)

      void createStarterNotes(
        () => {
          // Started
          showNotification({
            title: 'Welcome to NotePlan',
            message: 'Setting you up for a productive start...',
            icon: 'fa-sun text-orange-400',
          })
        },
        () => {
          // Ready for open daily note
          setSelectedRecordName('daily')
          selectedDateDispatch({ type: 'setDay', date: dayjs() })

          // This is necessary so that we reload the cached notes inside the TipTap editor object, otherwise clicking on "Start Here" will create a new note instead of opening the existing
          setShouldForceUpdateEditor((previous) => previous + 1)
        }
      ).finally(() => {
        setIsCreatingStarterNotes(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run once
  }, [])

  const createNoteMutation = useCreateNote((note: Note) => {
    if (note.recordName) {
      // Select the created note if it is not a folder and not a smart folder
      if (!isFolder(note) && !note.filename.startsWith('@')) {
        handleRevealNote(note.recordName)
      }

      // We just created a new teamspace, so add the starter notes
      if (note.noteType === NoteType.TEAM_SPACE) {
        showNotification({
          title: 'Teamspace',
          message: 'Setting up your teamspace...',
          icon: 'fa-screen-users text-green-400',
        })

        const recordName = note.recordName // capture the value for this callback
        void createTeamspaceStarterNotes(recordName, () => {
          // Open today's note of the teamspace, now with the updated caches
          setNeedsAttention(true)
          setSelectedRecordName(`daily_${recordName}`)
          selectedDateDispatch({ type: 'setDay', date: dayjs() })
          setBreadcrumb([recordName])
        })
      }
    }
  })

  const { openManageSubscription } = useManageSubscription()

  // function wrapped in useCallback to initiate the export of a note and it's children
  const handleExportSupabaseTeamspace = useCallback(
    (recordName: string) => {
      if (!teamNotes) {
        trackEvent('web.SidebarProvider.error', {
          message:
            'teamNotes undefined, this should not happen because it was triggered from a team note',
          recordName,
        })
        return
      }

      const entry = teamNotes.get(recordName)

      if (entry?.recordName) {
        showNotification({
          title: 'Exporting attachments...',
          message: 'Downloading, this can take a while.',
          icon: 'fa-download text-green-400',
          autoHide: false,
        })
        void exportNotes(teamNotes, entry.recordName)
          .then(() => {
            hideNotification()
          })
          .catch((error: unknown) => {
            hideNotification()
            showNotification({
              title: 'Export Error',
              message:
                error instanceof Error
                  ? error.message
                  : 'Failed to export notes',
              icon: errorIcon,
            })
          })
      } else {
        trackEvent('web.SidebarProvider.error', {
          message: 'teamspace note not found, this should not happen',
          recordName,
        })
      }
    },
    [hideNotification, showNotification, teamNotes]
  )

  const handleExportPrivateNotes = useCallback(() => {
    showNotification({
      title: 'Exporting private notes...',
      message: 'Downloading, this can take a while.',
      icon: 'fa-download text-green-400',
      autoHide: false,
    })

    void exportNotes(privateNotes)
      .then(() => {
        hideNotification()
      })
      .catch((error: unknown) => {
        hideNotification()
        showNotification({
          title: 'Export Error',
          message:
            error instanceof Error ? error.message : 'Failed to export notes',
          icon: errorIcon,
        })
      })
  }, [hideNotification, showNotification, privateNotes])

  // ensure the @Templates folder exists
  const { data: privateProjectNotesByTitle } = usePrivateProjectNotesByTitle()
  const [templatesFolderId, setTemplatesFolderId] = useState<
    string | undefined
  >(undefined)

  useEffect(() => {
    if (privateProjectNotesByTitle && templatesFolderId === undefined) {
      const templatesFolder = privateProjectNotesByTitle.get('@Templates')?.[0]
      if (templatesFolder) {
        setTemplatesFolderId(templatesFolder?.recordName)
      } else {
        createNoteMutation.mutate({
          filename: '@Templates',
          title: '@Templates',
          noteType: NoteType.PROJECT_NOTE,
          parent: undefined,
          isDir: true,
          recordName: uuid(),
        })
      }
    }
  }, [privateProjectNotesByTitle, templatesFolderId, createNoteMutation])

  const sidebarEntries: SidebarEntry[] = useMemo(
    () =>
      createSidebarEntries(
        user,
        isLoadingPrivateProjectNotes,
        privateNotes,
        isLoadingTeamProjectNotes,
        teamNotes,
        createNoteMutation.mutate,
        onOpenCommandBar,
        openManageSubscription,
        openPaywall,
        isGuest,
        isExpired,
        handleExportPrivateNotes,
        templatesFolderId
      ),
    [
      user,
      isLoadingPrivateProjectNotes,
      privateNotes,
      isLoadingTeamProjectNotes,
      teamNotes,
      createNoteMutation.mutate,
      onOpenCommandBar,
      openManageSubscription,
      openPaywall,
      isGuest,
      isExpired,
      handleExportPrivateNotes,
      templatesFolderId,
    ]
  )
  const noteKey = useMemo(
    () => findNoteKey(sidebarEntries, notes, selectedRecordName, selectedDate),
    [sidebarEntries, notes, selectedRecordName, selectedDate]
  )

  const handleSelectRecordName = useCallback(
    (recordName: string) => {
      setSelectedRecordName(recordName)

      const teamspace =
        recordName.split('_').length > 0 ? recordName.split('_')[1] : undefined

      const timeframeMap = {
        weekly: 'week',
        daily: 'day',
        monthly: 'month',
        quarterly: 'quarter',
        yearly: 'year',
      } as const

      // Find which timeframe prefix matches the recordName
      const timeframePrefix = Object.keys(timeframeMap).find((prefix) =>
        recordName.startsWith(prefix)
      )

      if (timeframePrefix) {
        const targetTimeframe =
          timeframeMap[timeframePrefix as keyof typeof timeframeMap]

        // Transform if we're in a different timeframe or different teamspace
        if (
          selectedDate.active !== targetTimeframe ||
          selectedDate.teamspace !== teamspace
        ) {
          selectedDateDispatch({
            type: 'transform',
            to: targetTimeframe,
            teamspace,
          })
        }
      }
    },
    [
      selectedDate.active,
      selectedDate.teamspace,
      selectedDateDispatch,
      setSelectedRecordName,
    ]
  )

  const cachedNotesQueryClient = useQueryClient({
    context: cachedNotesContext,
  })
  useEffect(() => {
    // check to make sure we use the new map data structure
    if (privateProjectNotes && Array.isArray(privateProjectNotes)) {
      void cachedNotesQueryClient.invalidateQueries({
        queryKey: cacheKeys.privateNotes(),
        refetchType: 'all',
      })
    }
    if (teamProjectNotes && Array.isArray(teamProjectNotes)) {
      void cachedNotesQueryClient.invalidateQueries({
        queryKey: cacheKeys.teamNotes(),
        refetchType: 'all',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // scroll to the selected record name
  useEffect(() => {
    if (needsAttention) {
      try {
        const element = document.getElementById(selectedRecordName)
        element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      } catch (error) {
        console.error('Failed to scroll to element:', error)
      }
      setBreadcrumb([])
      setNeedsAttention(false)
    }
  }, [selectedRecordName, needsAttention])

  // performance optimization
  const context = useMemo(
    () => ({
      noteKey,
      handleExportSupabaseTeamspace,
      handleSelectRecordName,
      handleRevealNote,
      privateProjectNotes: privateProjectNotes?.map,
      privateCalendarNotes: privateCalendarNotes?.map,
      privateNotes,
      teamProjectNotes: teamProjectNotes?.map,
      teamCalendarNotes: teamCalendarNotes?.map,
      teamNotes,
      notes,
      sidebarEntries,
      createNote: createNoteMutation.mutate,
      breadcrumb,
      setBreadcrumb,
      shouldForceUpdateEditor,
    }),
    [
      noteKey,
      handleExportSupabaseTeamspace,
      handleSelectRecordName,
      handleRevealNote,
      privateProjectNotes,
      privateCalendarNotes,
      privateNotes,
      teamProjectNotes,
      teamCalendarNotes,
      teamNotes,
      notes,
      sidebarEntries,
      createNoteMutation.mutate,
      breadcrumb,
      shouldForceUpdateEditor,
    ]
  )
  return (
    <SidebarContext.Provider value={context}>
      {children}
    </SidebarContext.Provider>
  )
}
