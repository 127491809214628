import {
  type DefaultBlockSchema,
  type PartialBlock,
  type PartialInlineContent,
  type StyledText,
} from '@packages/blocknote-core'
import classNames from 'classnames'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { Disclosure } from '@headlessui/react'
import { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import styles from '@packages/blocknote-core/extensions/Blocks/nodes/Block.module.css'
import { pluralize } from '../../utils/commons'
import { type FromNote } from '../../providers/ReferencesContext'
import { type SearchContentResult } from '../search/Search'
import { useSaveNote } from '../../hooks/useSaveNote'
import useNote from '../../hooks/useNote'
import { toggleTodoStateInLine } from '../../utils/syncUtils'
import { useIsOnline } from '../../providers/NetworkProvider'
import { trackError } from '../../lib/analytics'

// Define types for task-specific props based on the defaultBlocks.ts
type TaskBlockProps = {
  checked: boolean
  cancelled: boolean
  scheduled: boolean
  flagged: number
}

// Type guard to check if a block is a task or check list item
function isTaskOrCheckListItem(
  block: PartialBlock<DefaultBlockSchema>
): boolean {
  return Boolean(
    block.type && ['taskListItem', 'checkListItem'].includes(block.type)
  )
}

// Type guard to safely cast block props to TaskBlockProps
function getTaskProps(
  block: PartialBlock<DefaultBlockSchema>
): TaskBlockProps | undefined {
  if (isTaskOrCheckListItem(block) && block.props) {
    return block.props as TaskBlockProps
  }
  return undefined
}

// Type for note reference data
type NoteReferenceData = {
  recordName: string
  noteType: number
  filename: string
  parent?: string
  content: string
  lineIndex: number
}

// Custom hook for handling checkbox state and database updates
function useCheckboxToggleWithSave(
  block: PartialBlock<DefaultBlockSchema>,
  noteReference: NoteReferenceData
) {
  const [saveError, setSaveError] = useState<Error | undefined>(undefined)
  const saveAttemptReference = useRef(0) // Track save attempts to handle retries
  const processingReference = useRef(false) // Track processing state to prevent race conditions
  const timeoutReference = useRef<NodeJS.Timeout>() // Store timeout ID for cleanup

  // Get task props if this is a task or check list item
  const taskProps = getTaskProps(block)

  // State for UI checkbox
  const [localChecked, setLocalChecked] = useState<boolean | undefined>(
    taskProps?.checked
  )

  // Load the full note data - this will be our single source of truth
  const { data: existingNote, error: noteError } = useNote({
    noteType: noteReference.noteType,
    recordName: noteReference.recordName,
    filename: noteReference.filename,
    parent: noteReference.parent,
  })

  // Get the current content line from the note
  const currentContent = useMemo(() => {
    if (!existingNote?.content) {
      return noteReference.content
    }

    const contentArray = existingNote.content.split('\n')
    return noteReference.lineIndex >= 0 &&
      noteReference.lineIndex < contentArray.length
      ? contentArray[noteReference.lineIndex]
      : noteReference.content
  }, [existingNote, noteReference.lineIndex, noteReference.content])

  // Handle note loading errors
  useEffect(() => {
    if (noteError) {
      console.error('Error loading note:', noteError)
      trackError('useCheckboxToggleWithSave', 'Failed to load note', {
        noteType: noteReference.noteType,
        recordName: noteReference.recordName,
        filename: noteReference.filename,
        error:
          noteError instanceof Error ? noteError.message : String(noteError),
      })
      setSaveError(
        noteError instanceof Error ? noteError : new Error(String(noteError))
      )
    }
  }, [
    noteError,
    noteReference.filename,
    noteReference.noteType,
    noteReference.recordName,
  ])

  // Update local checked state when task props change
  useEffect(() => {
    setLocalChecked(taskProps?.checked)
  }, [taskProps?.checked])

  // This is for saving the note after modifying the content
  const saveMutate = useSaveNote(() => {
    // Reset states after successful save
    processingReference.current = false
    setSaveError(undefined)
    saveAttemptReference.current = 0
  })

  // Handle checkbox click
  const handleCheckboxClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      if (processingReference.current || !currentContent || !existingNote) {
        return // Prevent multiple clicks while processing
      }

      // Update local state immediately for UI feedback
      setLocalChecked((previous) => !previous)
      processingReference.current = true

      // Toggle the todo state in the line
      const newLine = toggleTodoStateInLine(currentContent)
      if (!newLine) {
        processingReference.current = false
        return
      }

      // Prepare the updated content
      const contentArray = existingNote.content?.split('\n') ?? []
      if (
        noteReference.lineIndex < 0 ||
        noteReference.lineIndex >= contentArray.length
      ) {
        processingReference.current = false
        return
      }

      contentArray[noteReference.lineIndex] = newLine
      const newContent = contentArray.join('\n')

      // Use a safe approach to parse attachments
      let attachmentsArray: string[] = []
      try {
        if (existingNote.attachments && existingNote.attachments.length > 0) {
          const parsed = JSON.parse(existingNote.attachments) as unknown[]
          if (Array.isArray(parsed)) {
            attachmentsArray = parsed.map(String)
          }
        }
      } catch (error) {
        console.error('Failed to parse attachments:', error)
        trackError('useCheckboxToggleWithSave', 'Failed to parse attachments', {
          noteType: existingNote.noteType,
          recordName: existingNote.recordName,
          filename: existingNote.filename,
          error: error instanceof Error ? error.message : String(error),
        })
      }

      // Save the note with the updated content
      saveMutate.mutate({
        filename: existingNote.filename,
        recordName: existingNote.recordName,
        content: newContent,
        attachments: attachmentsArray,
        noteType: existingNote.noteType,
        parent: existingNote.parent,
        modificationDate: new Date(),
      })
    },
    [currentContent, existingNote, noteReference.lineIndex, saveMutate]
  )

  // Handle save errors and retry logic
  useEffect(() => {
    if (saveMutate.error) {
      const error = saveMutate.error
      console.error('Error saving note:', error)
      trackError('useCheckboxToggleWithSave', 'Failed to save note', {
        error: error instanceof Error ? error.message : String(error),
        retryAttempt: saveAttemptReference.current,
      })
      setSaveError(error)

      // Implement retry logic with exponential backoff
      const maxRetries = 3
      if (saveAttemptReference.current < maxRetries) {
        const delay = Math.pow(2, saveAttemptReference.current) * 1000 // Exponential backoff
        saveAttemptReference.current += 1

        // Store timeout ID in ref for cleanup
        timeoutReference.current = setTimeout(() => {
          processingReference.current = false
          // This will allow another save attempt
        }, delay)
      } else {
        // Give up after max retries
        processingReference.current = false
      }
    }

    // Cleanup function to clear timeout when component unmounts
    return () => {
      if (timeoutReference.current) {
        clearTimeout(timeoutReference.current)
      }
    }
  }, [saveMutate.error])

  return {
    localChecked,
    isProcessing: processingReference.current,
    saveError,
    handleCheckboxClick,
  }
}

// Component for rendering a search result block
export function SearchResultBlock({
  result,
  onSelectNote,
  keyword,
}: {
  result: SearchContentResult
  onSelectNote: () => void
  keyword?: string
}) {
  const block = result.block

  // Use the shared hook for checkbox state and database updates
  const { localChecked, isProcessing, saveError, handleCheckboxClick } =
    useCheckboxToggleWithSave(block, result)

  return (
    <>
      <BlockRenderer
        block={block}
        localChecked={localChecked}
        onCheckboxClick={handleCheckboxClick}
        onSelectNote={onSelectNote}
        isProcessing={isProcessing}
        keyword={keyword}
      />
      {saveError && (
        <div className='ml-6 mt-1 text-xs text-red-500'>
          Error saving: {saveError.message}
        </div>
      )}
    </>
  )
}

// Component for rendering a partial block
function ReferenceBlock({
  recordName,
  fromNote,
  line,
  onSelectNote,
  keyword,
}: {
  recordName: string
  fromNote: FromNote
  line: {
    index: number
    content: string
    block: PartialBlock<DefaultBlockSchema>
  }
  onSelectNote: () => void
  keyword?: string
}) {
  // Use the shared hook for checkbox state and database updates
  const { localChecked, isProcessing, saveError, handleCheckboxClick } =
    useCheckboxToggleWithSave(line.block, {
      recordName: recordName,
      noteType: fromNote.noteType,
      filename: fromNote.filename,
      parent: fromNote.parent,
      content: line.content,
      lineIndex: line.index,
    })

  return (
    <>
      <BlockRenderer
        block={line.block}
        localChecked={localChecked}
        onCheckboxClick={handleCheckboxClick}
        onSelectNote={onSelectNote}
        isProcessing={isProcessing}
        keyword={keyword}
      />
      {saveError && (
        <div className='ml-6 mt-1 text-xs text-red-500'>
          Error saving: {saveError.message}
        </div>
      )}
    </>
  )
}

// Shared component for rendering the block UI
function BlockRenderer({
  block,
  localChecked,
  onCheckboxClick,
  onSelectNote,
  isProcessing = false,
  keyword,
}: {
  block: PartialBlock<DefaultBlockSchema>
  localChecked: boolean | undefined
  onCheckboxClick: (event: React.MouseEvent) => void
  onSelectNote: () => void
  isProcessing?: boolean
  keyword?: string
}) {
  // Check if this is a task or check list item
  const isTaskBlock = isTaskOrCheckListItem(block)

  // Get task props if applicable
  const taskProps = getTaskProps(block)

  // Default props for all blocks
  const defaultFolded = block.props?.folded as boolean | undefined
  const defaultVisible = block.props?.visible as boolean | undefined

  return (
    <div
      className={`${styles.blockGroup} cursor-pointer`}
      data-node-type='blockGroup'
      onClick={onSelectNote}
    >
      <div
        data-id={block.id}
        className={styles.blockOuter}
        data-node-type='block-outer'
      >
        <div className={styles.block} data-node-type='blockContainer'>
          <div
            className={classNames({
              'flex items-center': true,
              '-ml-3':
                block.children &&
                block.children.length > 0 &&
                block.type !== 'heading',
              '-ml-2':
                block.children &&
                block.children.length > 0 &&
                block.type === 'heading',
            })}
          >
            <div
              className={classNames({
                pulse: isProcessing,
                [styles.blockContent]: true,
                'text-zinc-500 dark:text-zinc-400 font-bold':
                  block.type === 'heading',
                'text-[15px] dark:opacity-90': block.type !== 'heading',
              })}
              data-content-type={block.type}
              data-checked={localChecked ?? taskProps?.checked ?? false}
              data-cancelled={taskProps?.cancelled ?? false}
              data-scheduled={taskProps?.scheduled ?? false}
              data-flagged={taskProps?.flagged ?? 0}
              data-folded={defaultFolded ?? false}
              data-visible={defaultVisible ?? true}
            >
              {isTaskBlock && (
                <label onClick={onCheckboxClick}>
                  <input
                    type='checkbox'
                    checked={localChecked ?? false}
                    readOnly
                    disabled={isProcessing}
                  />
                </label>
              )}
              {block.content && (
                <BlockContent content={block.content} keyword={keyword} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function BlockContent({
  content,
  keyword,
}: {
  content: string | PartialInlineContent[]
  keyword?: string
}) {
  if (typeof content === 'string') {
    if (keyword) {
      return highlightText(content, keyword)
    }
    return <span>{content}</span>
  }
  return <InlineContent content={content} keyword={keyword} />
}

function highlightText(text: string, keyword: string) {
  const regex = new RegExp(`(${keyword})`, 'gi')
  const parts = text.split(regex)
  return (
    <span>
      {parts.map((part, index) => {
        if (part.toLowerCase() === keyword.toLowerCase()) {
          return <mark key={index}>{part}</mark>
        }
        return part
      })}
    </span>
  )
}

export function InlineContent({
  content,
  className,
  keyword,
}: {
  content: PartialInlineContent[]
  className?: string
  keyword?: string
}) {
  return (
    <div className={`break-words pr-4 ${className ?? ''}`}>
      {content.map((inlineContent: PartialInlineContent, index) => {
        if (inlineContent.type === 'link') {
          return (
            <a
              href={inlineContent.href}
              key={index}
              className={styles.inlineContent}
            >
              {typeof inlineContent.content === 'string'
                ? inlineContent.content
                : inlineContent.content.map((text, index) =>
                    renderStyledText(text, index)
                  )}
            </a>
          )
        }
        return renderStyledText(inlineContent, index, keyword)
      })}
    </div>
  )
}

function renderStyledText(
  content: StyledText,
  index: number,
  keyword?: string
) {
  const text = keyword ? highlightText(content.text, keyword) : content.text
  if (content.styles.bold) {
    return <strong key={index}>{text}</strong>
  }
  if (content.styles.italic) {
    return <em key={index}>{text}</em>
  }
  if (content.styles.strikethrough) {
    return <del key={index}>{text}</del>
  }
  if (content.styles.underlined) {
    return <u key={index}>{text}</u>
  }
  if (content.styles.highlighted) {
    return <mark key={index}>{text}</mark>
  }
  if (content.styles.timeString) {
    return (
      <span data-content-type='time-string' key={index}>
        {text}
      </span>
    )
  }
  if (content.styles.code) {
    return <code key={index}>{text}</code>
  }
  if (content.styles.hashtag) {
    return (
      <a data-hashtag='hashtag' key={index}>
        {text}
      </a>
    )
  }
  if (content.styles.wikilink) {
    return (
      <a data-wikilink='wikilink' key={index}>
        {text}
      </a>
    )
  }
  if (content.styles.datelink) {
    return (
      <a data-datelink='datelink' key={index}>
        {text}
      </a>
    )
  }

  return (
    <span className='styledText' data-type={content.type} key={index}>
      {text}
    </span>
  )
}

function NoteReferencedFrom({
  id,
  note,
  onSelectNote,
}: {
  id: string
  note: FromNote
  onSelectNote: (_recordName: string) => void
}) {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <Disclosure as='div' defaultOpen={isOpen} className='mt-2'>
      {({ open }) => (
        <>
          <Disclosure.Button
            onClick={() => {
              setIsOpen(!isOpen)
            }}
            className='flex'
          >
            <ChevronRightIcon
              className={classNames({
                'rotate-90': open,
                'text-gray-500 dark:text-gray-200 h-4 w-4 shrink-0 mr-1': true,
              })}
              aria-hidden='true'
            />
            <span className='flex items-center justify-between text-xs font-bold'>
              {note.title}
            </span>
          </Disclosure.Button>
          <Disclosure.Panel className='ml-10'>
            {note.lines.map((line, index) => (
              <ReferenceBlock
                key={index}
                recordName={id}
                fromNote={note}
                line={line}
                onSelectNote={() => {
                  onSelectNote(id)
                }}
              />
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

// Props for the NoteReference component
type NoteReferenceComponentProps = {
  references: Map<string, FromNote>
  onSelectNote: (_recordName: string) => void
}

export function NoteReference({
  references,
  onSelectNote,
}: NoteReferenceComponentProps) {
  const [isOpen, setIsOpen] = useLocalStorage('showReferences', true)
  const notes = [...references.entries()]
    .map(([recordName, note]) => ({ recordName, note }))
    .sort((a, b) => a.note.title.localeCompare(b.note.title))
  const isOnline = useIsOnline()

  return (
    <div className={classNames('relative', { 'opacity-10': !isOnline })}>
      <Disclosure
        as='div'
        defaultOpen={isOpen}
        className='mx-auto mt-3 w-full max-w-3xl cursor-default'
        style={{ paddingInline: '54px' }}
      >
        {({ open }) => (
          <>
            <Disclosure.Button
              as='h1'
              className='flex items-center text-xs font-bold uppercase opacity-60'
              onClick={() => {
                setIsOpen(!isOpen)
              }}
            >
              <ChevronRightIcon
                className={classNames({
                  'rotate-90': open,
                  'text-gray-500 dark:text-gray-200 h-4 w-4 shrink-0 mr-1':
                    true,
                })}
                aria-hidden='true'
              />
              <span className='opacity-60'>
                {references.size} {pluralize('Reference', references.size)}
              </span>
            </Disclosure.Button>
            <Disclosure.Panel className='my-3 rounded-md bg-zinc-100/70 px-0 py-1.5 dark:bg-zinc-800'>
              <div>
                {notes.map(
                  (item) =>
                    item.note.lines.length > 0 && (
                      <NoteReferencedFrom
                        key={item.recordName}
                        id={item.recordName}
                        note={item.note}
                        onSelectNote={onSelectNote}
                      />
                    )
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      {!isOnline && <div className='absolute left-0 top-0 h-full w-full' />}
    </div>
  )
}
