import { InputRule } from '@tiptap/core'
import { createTipTapBlock } from '../../../../api/block'
import { handleEnter, handleAttribute } from '../ListItemKeyboardShortcuts'
import { TaskListItemNodeView } from './TaskListItemNodeView'
import { TaskListItemHTMLParser } from './TaskListItemHTMLParser'
import { TaskListItemListHTMLRender } from './TaskListItemHTMLRender'

export const TaskListItemBlockContent = createTipTapBlock<'taskListItem'>({
  name: 'taskListItem',
  content: 'inline*',

  // This is needed to detect when the user types a task item character
  addInputRules() {
    let taskItemCharacters: string[] =
      this.options.editor.options.taskItemCharacters || []

    const rules = []

    // Always add a rule to detect just "[ ]" pattern regardless of taskItemCharacters
    rules.push(
      new InputRule({
        find: new RegExp(`^\\[\\s\\]\\s$`),
        handler: ({ state, chain, range }) => {
          chain()
            .BNUpdateBlock(state.selection.from, {
              type: this.name,
              props: {
                checked: false,
                canceled: false,
                scheduled: false,
                flagged: 0,
                folded: false,
              },
            })
            // Removes the characters used to set the task
            .deleteRange({ from: range.from, to: range.to })
        },
      })
    )

    // Only add character-based rules if taskItemCharacters is not empty
    if (taskItemCharacters.length > 0) {
      // Add rules for each task item character
      taskItemCharacters.forEach((character: string) => {
        rules.push(
          new InputRule({
            find: new RegExp(`^[${character}]\\s$`),
            handler: ({ state, chain, range }) => {
              chain()
                .BNUpdateBlock(state.selection.from, {
                  type: this.name,
                  props: {
                    checked: false,
                    canceled: false,
                    scheduled: false,
                    flagged: 0,
                    folded: false,
                  },
                })
                // Removes the character used to set the list
                .deleteRange({ from: range.from, to: range.to })
            },
          })
        )
      })
    }

    return rules
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => handleEnter(this.editor),
      'Cmd-d': () => handleAttribute(this.editor, 'checked'),
      'Cmd-s': () => handleAttribute(this.editor, 'cancelled'),
    }
  },

  addAttributes() {
    return {
      flagged: {
        default: 0,
        parseHTML: (element) => element.getAttribute('data-flagged'),
        renderHTML: (attributes) => ({
          'data-flagged': attributes.flagged,
        }),
      },
      folded: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-folded'),
        renderHTML: (attributes) => ({
          'data-folded': attributes.folded,
        }),
      },
      checked: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-checked'),
        renderHTML: (attributes) => ({
          'data-checked': attributes.checked,
        }),
      },
      cancelled: {
        default: false,
        keepOnSplit: false,
        parseHTML: (element) => element.getAttribute('data-cancelled'),
        renderHTML: (attributes) => ({
          'data-cancelled': attributes.cancelled,
        }),
      },
      scheduled: {
        default: false,
        keepOnSplit: false,
        parseHTML: (element) => element.getAttribute('data-scheduled'),
        renderHTML: (attributes) => ({
          'data-scheduled': attributes.scheduled,
        }),
      },
    }
  },

  parseHTML() {
    return TaskListItemHTMLParser(this.name)
  },

  renderHTML({ HTMLAttributes }) {
    return TaskListItemListHTMLRender(this.name, HTMLAttributes)
  },

  addNodeView() {
    return ({ node, getPos, editor }) => {
      return TaskListItemNodeView(node, editor, getPos, this.name)
    }
  },
})
