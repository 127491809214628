import JSZip from 'jszip'
import { type UseMutateFunction } from '@tanstack/react-query'
import { Menu, MenuButton, MenuHeader, MenuItem } from '@szhsin/react-menu'
import { v4 as uuid } from 'uuid'
import { type CreateOptions } from '../../hooks/useCreateNote'
import {
  showTeamspaceSignIn,
  showTeamspaceSignOut,
} from '../../providers/UserProvider'
import { AuthType, AuthenticatedUser } from '../../utils/User'
import {
  type Note,
  NoteType,
  SourceDatabase,
  isCalendarNote,
  Attachment,
} from '../../utils/syncUtils'
import {
  type SelectedDate,
  selectedDateToKey,
} from '../../providers/SelectedDateProvider'
import { type useNoteConfig } from '../../hooks/useNote'
import { updateAttachmentUrls } from '../../lib/supabase/noteUtils'
import { trackError } from '../../lib/analytics'
import { CacheData } from '../../providers/CachedNotesProvider'
import { removeExtension } from '../../utils/syncUtils'

export function notesToNotesTree(
  notes: Map<string, Note>
): [Note[], Note | undefined] {
  // Given a map of notes, return a tree of notes
  // A folder is indicated by isFolder = true
  const tree: Note[] = []
  // console.log('[SidebarEntry] creating a file tree...', notes);

  if (notes.size === 0) {
    // eslint-disable-next-line no-console
    console.info('[SidebarEntry] No notes found, return')
    return [tree, undefined]
  }

  if (!(notes instanceof Map)) {
    // eslint-disable-next-line no-console
    console.debug('[SidebarEntry] Notes are not a map, return')
    return [tree, undefined]
  }

  // Check what the sourceDatabase is
  const firstNote = [...notes.values()][0]
  let sourceDatabase = firstNote.source

  // Check for parent as fallback.
  if (!sourceDatabase) {
    // Test if any of the notes has parent defined as fallback indicator
    for (const note of notes.values()) {
      if (note.parent) {
        sourceDatabase = SourceDatabase.SUPABASE
        continue
      }
    }
  }

  if (sourceDatabase === SourceDatabase.SUPABASE) {
    // Case: Supabase
    // populate notes with additional info
    const extendedNotes = [...notes.values()]
      .filter((note) =>
        [
          NoteType.ASSET_PROJECT_NOTE,
          NoteType.PROJECT_NOTE,
          NoteType.TEAM_SPACE,
          NoteType.TEAM_SPACE_NOTE,
        ].includes(note.noteType)
      )
      .map((note) => {
        return {
          ...note,
          title:
            note.title ??
            removeExtension(note.filename, note.isFolder ?? false),
          children: [],
        } as Note
      })

    // recursively build the tree by nesting the notes with a parent attribute into the parent
    const buildTree = (parent: Note) => {
      const children = extendedNotes.filter(
        (note) => note.parent === parent.recordName
      )
      parent.children = children
      for (const child of children) {
        buildTree(child)
      }
    }
    const rootNotes = extendedNotes.filter(
      // eslint-disable-next-line unicorn/no-null -- null is a valid value for parent
      (note) => note.parent === undefined || note.parent == null
    )

    for (const note of rootNotes) {
      buildTree(note)
    }
    tree.push(...rootNotes)
  } else {
    // console.log('[SidebarEntry] The data is from CloudKit');

    // If this is in any case not sorted, we get the wrong results, the algo depends on the array to be sorted
    const sortedNotes = [...notes.values()]
      .filter((note) =>
        [NoteType.ASSET_PROJECT_NOTE, NoteType.PROJECT_NOTE].includes(
          note.noteType
        )
      )
      .sort((a, b) => a.filename.localeCompare(b.filename))

    // Case: CloudKit
    // The filename indicates the level of the note in the tree. Each level is separated by a slash
    // If the parent folder of the note does not exist, don't add it to the tree
    for (const note of sortedNotes) {
      const filename = note.filename
      let path = ''

      const parts = filename.split('/')
      let parent = tree

      for (let index = 0; index < parts.length; index++) {
        const part = parts[index]

        // Reassemble the path as filename, we need this to search the existing list and avoid duplicates
        path += (index > 0 ? '/' : '') + part

        const existing = parent.find((item) => item.filename === path)
        if (existing) {
          if (note.isFolder) {
            existing.isFolder = true
          }
          parent = existing.children ?? []
        } else {
          // Anything but the last part must be a folder (unless it's just one part)
          // That's because in a path only the last part can be the actual filename (not a folder)
          const isFolder = note.isFolder ?? index < parts.length - 1

          const item: Note = {
            filename: path,
            noteType: NoteType.PROJECT_NOTE,
            title: note.title ?? removeExtension(part, isFolder),
            recordName: note.recordName,
            children: [],
            isFolder,
            source: note.source,
          }

          parent.push(item)
          parent = item.children ?? []
        }
      }
    }

    // move items where isFolder == false and has children to the @Trash folder
    const trashFolder = tree.find((item) => item.filename === '@Trash')
    if (trashFolder) {
      const trashItems = tree.filter(
        (item) => !item.isFolder && item.children && item.children.length > 0
      )
      for (const item of trashItems) {
        trashFolder.children?.push(item)
        // remove the item from the tree
        const index = tree.indexOf(item)
        if (index !== -1) {
          tree.splice(index, 1)
        }
      }
    }
  }
  // find the folder with the filename @Templates
  const templates = tree.find(
    (item) => item.isFolder && item.filename === '@Templates'
  )
  traverseAndSort(templates?.children ?? [])

  // filter out folders that starts with @ at the top level
  const filteredTree = tree.filter(
    (item) => !item.isFolder || !item.filename.startsWith('@')
  )
  traverseAndSort(filteredTree)

  // console.log('[SidebarEntry] file tree loaded', filteredTree)
  return [filteredTree, templates]
}

// traverse and sort the entire tree
function traverseAndSort(nodes: Note[]) {
  nodes.sort(sortNotes)
  for (const node of nodes) {
    if (node.children) {
      traverseAndSort(node.children)
    }
  }
}

function sortNotes(a: Note, b: Note) {
  if (a.isFolder && !b.isFolder) {
    return -1
  } else if (!a.isFolder && b.isFolder) {
    return 1
  }
  // Sort emojis down, like in the native app
  const regexEmoji = /^\p{Extended_Pictographic}/u
  const aStartsWithEmoji = regexEmoji.test(a.title ?? '')
  const bStartsWithEmoji = regexEmoji.test(b.title ?? '')
  if (aStartsWithEmoji && !bStartsWithEmoji) {
    return 1
  } else if (!aStartsWithEmoji && bStartsWithEmoji) {
    return -1
  }

  // Natural sort for numeric parts
  const aTitle = a.title ?? ''
  const bTitle = b.title ?? ''
  return aTitle.localeCompare(bTitle, undefined, { numeric: true })
}

// Converts a note to a sidebar item
export function noteToSidebarEntry({
  item,
  user,
  disabled = false,
  parentId,
  templatesContext = false,
}: {
  item: Note
  user: AuthenticatedUser
  disabled: boolean
  parentId?: string
  templatesContext?: boolean
}): SidebarEntry {
  let hasAdminRights = item.source === SourceDatabase.CLOUDKIT
  let isOwner = item.source === SourceDatabase.CLOUDKIT

  if (
    user.authType === AuthType.SUPABASE ||
    user.authType === AuthType.CLOUDKIT_SUPABASE
  ) {
    hasAdminRights =
      (item.owner === user.teamUserId ||
        item.admins?.includes(user.teamUserId)) ??
      false
    isOwner = item.owner === user.teamUserId
  }

  const sidebarEntry: SidebarEntry = {
    noteType: item.noteType,
    recordName: item.recordName,
    filename: item.filename,
    title: item.title,
    icon: 'fal fa-file-lines',
    color: 'text-gray-600 dark:text-gray-400',
    parent: item.parent ?? parentId,
    hasAdminRights,
    isOwner,
    source: item.source,
    disabled: disabled,
    templatesContext: templatesContext,
  }

  if (item.isFolder) {
    sidebarEntry.icon = 'far fa-folder'
    sidebarEntry.color = 'text-blue-500'
    sidebarEntry.children = []
  }

  if (item.noteType === NoteType.TEAM_SPACE) {
    sidebarEntry.icon = 'far fa-screen-users'
    sidebarEntry.color = 'text-green-500'
    sidebarEntry.childNoteType = NoteType.TEAM_SPACE_NOTE
  }

  if (item.children && item.children.length > 0) {
    sidebarEntry.children = item.children.map((child) =>
      noteToSidebarEntry({
        item: child,
        user,
        disabled,
        parentId: item.recordName,
        templatesContext: templatesContext,
      })
    )
  }

  return sidebarEntry
}

// Recursively build a map from parentRecordName to its child notes
function populateNotesMap(notes: Map<string, Note>): Map<string, Note[]> {
  const notesMap = new Map<string, Note[]>()
  for (const note of notes.values()) {
    const parent = note.parent ?? 'root'
    if (!notesMap.has(parent)) {
      notesMap.set(parent, [])
    }
    notesMap.get(parent)?.push(note)
  }
  return notesMap
}

async function downloadAndAddAttachments(
  note: Note,
  attachmentsZip: JSZip,
  attachmentFolderName: string
) {
  await updateAttachmentUrls(note)
  if (!note.attachments || note.attachments.length === 0) {
    return
  }

  const attachments = JSON.parse(note.attachments) as string[]
  const downloadPromises = attachments.map(async (attachment) => {
    const asset = JSON.parse(attachment) as Attachment
    const filename = asset.filename
    const url = asset.url
    if (url) {
      try {
        const response = await fetch(url)
        if (!response.ok) {
          trackError(
            'downloadAndAddAttachments',
            `Failed to download asset. Status: ${response.statusText}`,
            {
              filename,
              url,
            }
          )
          throw new Error(
            `Failed to download asset. Status: ${response.statusText}`
          )
        }
        const blob = await response.blob()
        attachmentsZip.file(filename, blob)
        // Replace markdown links in the note content
        const markdownLinkRegex = new RegExp(
          `(\\!\\[.*?\\]\\()(${filename})(\\))`,
          'g'
        )
        note.content = note.content?.replace(
          markdownLinkRegex,
          `$1${attachmentFolderName}/$2$3`
        )
      } catch (error) {
        trackError('downloadAndAddAttachments', 'Error downloading asset', {
          error,
        })
      }
    }
  })
  await Promise.all(downloadPromises)
}

async function addNoteToZip(
  parentZip: JSZip,
  calendarNotesFolder: JSZip,
  notesMap: Map<string, Note[]>,
  note: Note
) {
  // Sanitize title to be used in filenames
  const sanitizedTitle = note.title
    ? note.title.replaceAll(/["#%()*/:<>?@\\|]/g, '')
    : note.filename.split('.')[0]

  if (note.isFolder) {
    const folderZip = parentZip.folder(sanitizedTitle)
    if (folderZip && note.recordName) {
      const children = notesMap.get(note.recordName) ?? []
      for (const childNote of children) {
        await addNoteToZip(folderZip, calendarNotesFolder, notesMap, childNote)
      }
    } else {
      trackError('addNoteToZip', 'invalid folder name', {
        folderName: sanitizedTitle,
      })
    }
  } else {
    if (note.attachments && note.attachments.length > 0) {
      const attachmentsFolderName = isCalendarNote(note.noteType)
        ? `${note.filename.split('.')[0]}_attachments`
        : `${sanitizedTitle}_attachments`
      const attachmentsZip = isCalendarNote(note.noteType)
        ? calendarNotesFolder.folder(attachmentsFolderName)
        : parentZip.folder(attachmentsFolderName)
      if (attachmentsZip) {
        await downloadAndAddAttachments(
          note,
          attachmentsZip,
          attachmentsFolderName
        )
      } else {
        trackError('addNoteToZip', 'invalid attachments folder name', {
          attachmentsFolderName,
        })
      }
    }

    // Use sanitized title or filename
    const fileName = sanitizedTitle ? `${sanitizedTitle}.md` : note.filename
    const targetFolder = isCalendarNote(note.noteType)
      ? calendarNotesFolder
      : parentZip
    targetFolder.file(fileName, note.content ?? '')
  }
}

export async function exportNotes(
  notes: Map<string, Note>,
  parentRecordName?: string
) {
  const zip = new JSZip()
  const calendarNotesFolder = zip.folder('Calendar Notes')
  if (!calendarNotesFolder) {
    const errorMessage = 'Failed to create calendar notes folder.'
    trackError('exportNotes', errorMessage)
    throw new Error(errorMessage)
  }

  const startTime = performance.now() // Start measuring time

  const notesMap = populateNotesMap(notes)
  const rootKey = parentRecordName ?? 'root'
  const rootNotes = notesMap.get(rootKey) ?? []

  if (rootNotes.length === 0) {
    const errorMessage = 'No notes available for export.'
    trackError('exportNotes', errorMessage)
    throw new Error(errorMessage)
  }

  for (const note of rootNotes) {
    await addNoteToZip(zip, calendarNotesFolder, notesMap, note)
  }

  const endTime = performance.now() // End measuring time
  // eslint-disable-next-line no-console
  console.debug(
    `Downloaded all attachments in ${(endTime - startTime).toFixed(2)}ms`
  )

  await zip
    .generateAsync({ type: 'blob' })
    .then((content) => {
      const filename = parentRecordName
        ? notes.get(parentRecordName)?.title ?? `${parentRecordName}.zip`
        : 'NotePlan Private Notes.zip'
      const url = window.URL.createObjectURL(content)
      const link = document.createElement('a')
      link.href = url
      link.download = filename
      link.click()
    })
    .catch(() => {
      const errorMessage = 'Failed to generate ZIP file.'
      trackError('exportNotes', errorMessage)
      throw new Error(errorMessage)
    })
}

export type SidebarEntry = {
  recordName: string
  title: string
  filename?: string
  icon?: string
  color?: string
  header?: boolean
  children?: SidebarEntry[]
  noteType?: NoteType
  childNoteType?: NoteType
  disabled?: boolean
  parent?: string
  selectable?: boolean
  menu?: React.ReactNode
  isLoading?: boolean
  isHidden?: boolean
  hideContext?: boolean
  templatesContext?: boolean
  hasAdminRights?: boolean
  isOwner?: boolean
  action?: () => boolean
  shortcut?: string
  source?: SourceDatabase
  isLocked?: boolean
}

function createDailyNote(parent: string) {
  return {
    filename: 'Daily',
    recordName: `daily_${parent}`,
    color: 'text-orange-600 dark:text-orange-400',
    title: 'Daily',
    icon: 'fal fa-calendar-star',
    noteType: NoteType.TEAM_SPACE_CALENDAR_NOTE,
    parent,
    hideContext: true,
  }
}

function createWeeklyNote(parent: string) {
  return {
    filename: 'Weekly',
    recordName: `weekly_${parent}`,
    color: 'text-fuchsia-600 dark:text-fuchsia-500',
    title: 'Weekly',
    icon: 'fal fa-calendar-week',
    noteType: NoteType.TEAM_SPACE_CALENDAR_NOTE,
    parent,
    hideContext: true,
  }
}

export function createSidebarEntries(
  user: AuthenticatedUser,
  isLoadingPrivate: boolean,
  privateNotes: Map<string, Note>,
  isLoadingTeam: boolean,
  teamNotes: Map<string, Note>,
  createNote: UseMutateFunction<Note, Error, CreateOptions, CacheData>,
  showCommandBar: (_search: string) => void,
  openManageSubscription: () => void,
  openPaywall: () => void,
  isGuest: boolean,
  isExpired: boolean,
  handleExportPrivateNotes: () => void,
  templatesFolderId?: string
): SidebarEntry[] {
  const isSupabase =
    user.authType === AuthType.SUPABASE ||
    user.authType === AuthType.CLOUDKIT_SUPABASE
  const isTeamspace = isSupabase && teamNotes.size > 0

  const noteplan: SidebarEntry = {
    recordName: 'noteplan',
    title: 'NotePlan Beta',
    color: 'text-gray-400',
    header: true,
    hideContext: true,
    children: [
      // SETTINGS
      // Currently the settings are showing a teamspace (supabase) login for cloudkit users
      {
        recordName: 'settings',
        color: 'text-gray-600 dark:text-gray-400',
        title: '',
        icon: '',
        selectable: false,
        menu: (
          <Menu
            align='start'
            boundingBoxPadding='30px 0px 0px 0px'
            menuButton={
              <MenuButton className='relative w-full p-0 text-left align-middle'>
                <i
                  className='fa-regular far fa-gear ml-1 mr-2 h-1 w-4 text-sm'
                  aria-hidden='true'
                />
                Settings
              </MenuButton>
            }
          >
            {/* Show the email as header if available */}
            {isSupabase && (
              <MenuHeader className='pl-6 pr-2'>{user.email}</MenuHeader>
            )}

            {/* Team space (supabase) login/logout button */}
            {user.authType === AuthType.CLOUDKIT && (
              <MenuItem
                disabled={false}
                key='supabase-login'
                onClick={() => {
                  showTeamspaceSignIn()
                }}
              >
                Login to Teamspaces
              </MenuItem>
            )}
            {user.authType === AuthType.CLOUDKIT_SUPABASE && (
              <MenuItem
                disabled={false}
                key='supabase-logout'
                onClick={showTeamspaceSignOut}
              >
                Logout from Teamspaces
              </MenuItem>
            )}

            <MenuItem
              disabled={false}
              key='supabase'
              onClick={() => {
                openManageSubscription()
              }}
            >
              Manage Subscription
            </MenuItem>
            <MenuItem
              disabled={false}
              key='purchase'
              onClick={() => {
                openPaywall()
              }}
            >
              Purchase
            </MenuItem>
            <MenuItem
              disabled={false}
              key='download'
              onClick={() =>
                window.open(
                  'https://testflight.apple.com/join/O10uVN0K',
                  // 'https://apps.apple.com/app/apple-store/id1505432629?pt=118158142&ct=landing&mt=8',
                  '_blank'
                )
              }
            >
              Download iOS/macOS Beta
            </MenuItem>
            {user.authType === AuthType.SUPABASE && (
              <MenuItem
                disabled={false}
                key='export-private-notes'
                onClick={() => {
                  handleExportPrivateNotes()
                }}
              >
                Export Private Notes
              </MenuItem>
            )}
          </Menu>
        ),
      },
      {
        recordName: 'search',
        color: 'text-gray-600 dark:text-gray-400',
        title: 'Search',
        icon: 'far fa-search',
        hideContext: true,
        shortcut: '⌘+⇧+F',
        action: () => {
          return false
        },
      },
      {
        recordName: 'commandbar',
        color: 'text-gray-600 dark:text-gray-400',
        title: 'Command bar',
        icon: 'far fa-file-magnifying-glass',
        hideContext: true,
        selectable: false,
        action: () => {
          showCommandBar('')
          return true
        },
        shortcut: '⌘+J',
      },
      {
        recordName: 'create',
        color: 'text-gray-600 dark:text-gray-400',
        title: '',
        icon: '',
        selectable: false,
        menu: (
          <Menu
            align='start'
            boundingBoxPadding='30px 0px 0px 0px'
            menuButton={
              <MenuButton
                disabled={isGuest || isExpired}
                className={`relative w-full p-0 text-left align-middle ${isGuest || isExpired ? 'opacity-50' : ''}`}
              >
                <i
                  className='fa-regular fas fa-plus ml-1 mr-2 h-1 w-4 text-sm'
                  aria-hidden='true'
                />
                Create
                {(isGuest || isExpired) && (
                  <i
                    className='far fa-lock ml-2 h-1'
                    aria-hidden='true'
                    title='Subscribe to unlock'
                  />
                )}
              </MenuButton>
            }
          >
            {/* Create a new team space */}
            <MenuItem
              key='create teamspace'
              onClick={() => {
                if (isSupabase) {
                  const recordName = uuid()
                  createNote({
                    recordName,
                    noteType: NoteType.TEAM_SPACE,
                    parent: undefined,
                    isDir: true,
                  })
                } else {
                  showTeamspaceSignIn()
                }
              }}
            >
              <i
                className='far fa-screen-users mr-2 w-4 text-center'
                aria-hidden='true'
              />
              {isSupabase ? 'Teamspace' : 'Teamspace (login first)'}
            </MenuItem>
            <MenuItem
              key='create folder'
              onClick={() => {
                createNote({
                  recordName: uuid(),
                  noteType: NoteType.PROJECT_NOTE,
                  parent: undefined,
                  isDir: true,
                })
              }}
            >
              <i className='far fa-folder mr-2 w-4 text-center' />
              Folder
            </MenuItem>
            <MenuItem
              key='create note'
              onClick={() => {
                createNote({
                  recordName: uuid(),
                  noteType: NoteType.PROJECT_NOTE,
                  parent: undefined,
                })
              }}
            >
              <i className='far fa-file-lines mr-2 w-4 text-center' />
              Note
            </MenuItem>
          </Menu>
        ),
      },
    ],
  }

  const individualCalendarNotes: SidebarEntry[] = [
    {
      recordName: 'daily',
      color: 'text-orange-600 dark:text-orange-400',
      title: 'Daily',
      icon: 'fal fa-calendar-star',
      hideContext: true,
      disabled: isGuest,
    },
    {
      recordName: 'weekly',
      color: 'text-fuchsia-600 dark:text-fuchsia-500',
      title: 'Weekly',
      icon: 'fal fa-calendar-week',
      hideContext: true,
      disabled: isGuest,
    },
    {
      recordName: 'monthly',
      color: 'text-red-700 dark:text-red-600',
      title: 'Monthly',
      icon: 'fal fa-calendar-range',
      hideContext: true,
      disabled: isGuest,
    },
    {
      recordName: 'quarterly',
      color: 'text-orange-700 dark:text-orange-600',
      title: 'Quarterly',
      icon: 'fal fa-calendar-days',
      hideContext: true,
      disabled: isGuest,
    },
    {
      recordName: 'yearly',
      color: 'text-yellow-600 dark:text-yellow-500',
      title: 'Yearly',
      icon: 'fal fa-calendar-days',
      hideContext: true,
      disabled: isGuest,
    },
    // { recordName: 'spacer_' + uuid(), title: '', disabled: true },
  ]

  const calendarNotes: SidebarEntry = {
    recordName: 'calendar-notes',
    title: 'Calendar Notes',
    color: 'text-gray-400',
    header: true,
    isHidden: isTeamspace,
    children: individualCalendarNotes,
  }

  const projectNotes: SidebarEntry = {
    recordName: 'notes',
    title: isTeamspace ? 'Private Notes' : 'Notes',
    color: 'text-gray-400',
    header: true,
    isLoading: isLoadingPrivate,
    children: [],
    childNoteType: NoteType.PROJECT_NOTE,
    source:
      user.authType === AuthType.SUPABASE
        ? SourceDatabase.SUPABASE
        : SourceDatabase.CLOUDKIT,
    isLocked: isGuest,
  }

  if (isTeamspace) {
    // Add the calendar notes into the "private notes" section, so private and shared are better split up
    projectNotes.children?.push(...individualCalendarNotes)
  }

  const teamspaces: SidebarEntry = {
    recordName: 'teamspaces',
    title: 'Teamspaces',
    color: 'text-gray-400',
    header: true,
    isLoading: isLoadingTeam,
    isHidden: !isTeamspace,
    children: [],
    childNoteType: NoteType.TEAM_SPACE,
  }

  const [privateNotesTree, templates] = notesToNotesTree(privateNotes)
  projectNotes.children?.push(
    ...privateNotesTree.map((item) =>
      noteToSidebarEntry({
        item,
        user,
        disabled: isGuest,
      })
    )
  )

  if (teamNotes.size > 0) {
    const [teamNotesTree] = notesToNotesTree(teamNotes)
    teamspaces.children = teamNotesTree.map((item) =>
      noteToSidebarEntry({
        item,
        user,
        disabled: false, // Always allow teamspace access, as guest or full member
      })
    )

    const spacer = {
      recordName: `spacer_${uuid()}`,
      title: '',
      disabled: true,
    }

    // Add to each teamspace calendar notes
    for (const teamspace of teamspaces.children) {
      if (teamspace.noteType === NoteType.TEAM_SPACE) {
        const existingChildren = teamspace.children ?? []
        teamspace.children = [
          createDailyNote(teamspace.recordName),
          createWeeklyNote(teamspace.recordName),
          /*spacer,*/ ...existingChildren,
        ]
        if (teamspace !== teamspaces.children.at(-1)) {
          teamspace.children.push(spacer)
        }
      }
    }
  }

  const smartFolders: SidebarEntry = {
    recordName: 'smart-folders',
    title: 'Smart Folders',
    color: 'text-gray-400',
    header: true,
    children: [],
  }

  if (templatesFolderId) {
    smartFolders.children?.push({
      recordName: templatesFolderId,
      filename: '@Templates', // important to hide rename and delete menu items
      title: 'Templates',
      color: 'text-gray-400',
      icon: 'far fa-clipboard',
      noteType: NoteType.PROJECT_NOTE,
      templatesContext: true,
      children: templates?.children?.map((item) =>
        noteToSidebarEntry({
          item,
          user,
          disabled: isGuest,
          templatesContext: true,
        })
      ),
    })
  }

  return [
    noteplan,
    teamspaces,
    calendarNotes,
    projectNotes,
    smartFolders,
    getTagsFromNotes(privateNotes, teamNotes),
  ]
}

export function getTagsFromNotes(
  privateNotes: Map<string, Note>,
  teamNotes: Map<string, Note>
) {
  const tagMap = new Map()

  // Helper function to recursively add nested tags
  const addNestedTags = (tag, parent = null) => {
    if (tag.length <= 1) return

    let current = parent
    tag.split('/').forEach((part) => {
      const fullPath = current ? `${current}/${part}` : part
      part = part.replace(/^[@#]/, '')

      if (!tagMap.has(fullPath)) {
        tagMap.set(fullPath, {
          recordName: `search::::${fullPath}`, //`tag_${fullPath}`,
          title: part,
          fullPath,
          parent: current,
          // children: [],
          color: 'text-gray-400',
          hideContext: true,
          icon: fullPath.startsWith('#') ? 'far fa-hashtag' : 'far fa-at',
        })
      }
      current = fullPath
    })
  }

  // Process all notes
  const allNotes = new Map([...(privateNotes ?? []), ...(teamNotes ?? [])])
  allNotes.forEach((note) => {
    if (note.tags) {
      note.tags.forEach((tag: string) => {
        addNestedTags(tag)
      })
    }
  })

  // Build the hierarchical structure
  tagMap.forEach((tag) => {
    if (tag.parent) {
      const parentTag = tagMap.get(tag.parent)
      if (!parentTag.children) {
        parentTag.children = []
      }
      parentTag.children.push(tag)
    }
  })

  // Filter to get only top-level tags
  const topTags = Array.from(tagMap.values()).filter((tag) => !tag.parent)

  // Separate mentions and hashtags
  const mentions = topTags
    .filter((tag) => tag.fullPath.startsWith('@'))
    .sort((a, b) => a.fullPath.localeCompare(b.fullPath))
  const hashtags = topTags
    .filter((tag) => tag.fullPath.startsWith('#'))
    .sort((a, b) => a.fullPath.localeCompare(b.fullPath))

  return {
    recordName: 'tags',
    title: 'Tags',
    color: 'text-gray-400',
    header: true,
    children: [
      {
        recordName: 'mentions',
        title: `Mentions (${mentions.length})`,
        color: 'text-gray-400',
        icon: 'far fa-at',
        hideContext: true,
        children: mentions,
      },
      {
        recordName: 'hashtags',
        title: `Hashtags (${hashtags.length})`,
        color: 'text-gray-400',
        icon: 'far fa-hashtag',
        hideContext: true,
        children: hashtags,
      },
    ],
  }
}

function isValidCalendarNote(
  item: SidebarEntry,
  timeframe: string,
  active: string,
  selectedDate: SelectedDate
) {
  return (
    (item.recordName === timeframe ||
      (item.recordName.startsWith(`${timeframe}_`) &&
        item.noteType === NoteType.TEAM_SPACE_CALENDAR_NOTE)) &&
    selectedDate.active === active
  )
}

function searchSidebar(
  sidebarEntries: SidebarEntry[],
  recordName: string,
  selectedDate: SelectedDate
): useNoteConfig | undefined {
  for (const item of sidebarEntries) {
    if (item.recordName === recordName) {
      const noteType = item.noteType ?? NoteType.CALENDAR_NOTE
      let filename = item.filename

      if (
        !filename ||
        isValidCalendarNote(item, 'daily', 'day', selectedDate) ||
        isValidCalendarNote(item, 'weekly', 'week', selectedDate)
      ) {
        filename = selectedDateToKey(selectedDate)
      }

      return { noteType, recordName, filename, parent: item.parent }
    } else if (item.children) {
      const result = searchSidebar(item.children, recordName, selectedDate)
      if (result) {
        return result
      }
    }
  }
  return undefined
}

export function findNoteKey(
  sidebarEntries: SidebarEntry[],
  notes: Map<string, Note>,
  recordName: string,
  selectedDate: SelectedDate
): useNoteConfig {
  // First try to find the note in the sidebar
  const result = searchSidebar(sidebarEntries, recordName, selectedDate)

  if (result) {
    return result
  }

  // Fallback: try to find the note in the notes map
  const note = notes.get(recordName)
  if (note) {
    return {
      noteType: note.noteType,
      recordName,
      filename: note.filename,
      parent: note.parent ?? undefined,
    }
  }

  // We got here because of the initial load or if the note was deleted, so we need to return a calendar note as fallback
  if (selectedDate.teamspace) {
    return {
      noteType: NoteType.TEAM_SPACE_CALENDAR_NOTE,
      recordName,
      filename: selectedDateToKey(selectedDate),
      parent: selectedDate.teamspace,
    }
  }
  return {
    noteType: NoteType.CALENDAR_NOTE,
    recordName,
    filename: selectedDateToKey(selectedDate),
    parent: undefined,
  }
}
