import { Fragment, useRef, useEffect } from 'react'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { DocumentTextIcon } from '@heroicons/react/24/outline'
import { useDebounceCallback } from 'usehooks-ts'
import { filenameToDate, isCalendarNote } from '../../utils/syncUtils'
import classNames from 'classnames'
import { useSelectedDateDispatch } from '../../providers/SelectedDateProvider'
import { useSearchResults, SearchResult } from './hooks/useNoteSearchResults'
import { useSidebarProvider } from '../../providers/SidebarProvider'
import {
  CommandBarState,
  CommandBarItem,
  CommandBarAction,
  SearchState,
  OptionsState,
} from './types'
import { useOptionsFilter } from './hooks/useOptionsFilter'

// const quickActions = [
//   { name: 'Add new note...', icon: DocumentPlusIcon, shortcut: 'N', url: '#' },
//   { name: 'Add new folder...', icon: FolderPlusIcon, shortcut: 'F', url: '#' },
// ];

type Props = {
  state: CommandBarState
  dispatch: (action: CommandBarAction) => void
}

// #region Type Guards
function isSearchState(state: CommandBarState): state is SearchState {
  return state.visible && state.mode === 'search'
}

function isOptionsState(state: CommandBarState): state is OptionsState {
  return state.visible && state.mode === 'options'
}
// #endregion

// #region Custom Hooks
function useSearchMode(state: CommandBarState) {
  const { notes } = useSidebarProvider()
  const searchTerm = isSearchState(state) ? state.searchTerm : ''

  const { recent, filteredResults } = useSearchResults(
    isSearchState(state) ? [...notes.values()] : [],
    searchTerm,
    searchTerm
  )

  if (!isSearchState(state)) return []

  return (searchTerm === '' ? recent : filteredResults).map((result) => ({
    id: result.id,
    title: result.name,
    icon: DocumentTextIcon,
    type: result.type as CommandBarItem['type'],
    metadata: result,
  }))
}

function useOptionsMode(state: CommandBarState) {
  const filteredOptions = useOptionsFilter(
    isOptionsState(state) ? state.options : [],
    isOptionsState(state) ? state.searchTerm : ''
  )

  if (!isOptionsState(state)) return []

  return filteredOptions.map((option) => ({
    id: option,
    title: option,
    icon: DocumentTextIcon,
    type: 'option' as const,
  }))
}
// #endregion

export default function CommandBar({ state, dispatch }: Props) {
  const inputReference = useRef<HTMLInputElement>(null)

  const searchItems = useSearchMode(state)
  const optionItems = useOptionsMode(state)

  const items: CommandBarItem[] = state.visible
    ? isOptionsState(state)
      ? optionItems
      : searchItems
    : []

  const { handleSelectRecordName } = useSidebarProvider()
  // For selecting calendar notes (when searching for tags for example)
  const selectedDateDispatch = useSelectedDateDispatch()

  // Debounced search dispatch
  const debouncedDispatch = useDebounceCallback((searchTerm: string) => {
    dispatch({ type: 'updateSearch', searchTerm })
  }, 200)

  const handleSelect = (item: CommandBarItem) => {
    if (!state.visible) return

    if (item.type === 'option' && isOptionsState(state)) {
      const index = state.options.indexOf(item.title)
      window.dispatchEvent(
        new CustomEvent('commandBarOptionSelected', {
          detail: { value: item.title, index },
        })
      )
      dispatch({ type: 'hide' })
      return
    }

    if (item.type === 'tag') {
      dispatch({
        type: 'updateSearch',
        searchTerm: 'tag: ' + item.title,
      })
      return
    }

    const metadata = item.metadata as SearchResult
    if (
      metadata.noteType &&
      metadata.filename &&
      isCalendarNote(metadata.noteType)
    ) {
      const { date, timeframe } = filenameToDate(metadata.filename)
      if (date) {
        if (timeframe === 'day') {
          selectedDateDispatch({ type: 'setDay', date })
        } else if (timeframe === 'week') {
          selectedDateDispatch({
            type: 'setWeek',
            week: date.week(),
            year: date.year(),
          })
        }
      }
      dispatch({ type: 'hide' })
      return
    }

    handleSelectRecordName(item.id)
    dispatch({ type: 'hide' })
  }

  // Keyboard shortcuts
  useEffect(() => {
    const handleKeyDown = (keyboardEvent: KeyboardEvent) => {
      // Toggle command bar with Cmd/Ctrl + J
      if (
        (keyboardEvent.metaKey || keyboardEvent.ctrlKey) &&
        keyboardEvent.key === 'j'
      ) {
        keyboardEvent.preventDefault()
        if (state.visible) {
          dispatch({ type: 'hide' })
        } else {
          dispatch({ type: 'showSearch', searchTerm: '' })
        }
        return
      }

      // Select all text with Cmd/Ctrl + A
      if (
        (keyboardEvent.metaKey || keyboardEvent.ctrlKey) &&
        keyboardEvent.key === 'a' &&
        inputReference.current === document.activeElement
      ) {
        keyboardEvent.preventDefault()
        inputReference.current?.select()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [state.visible, dispatch])

  return (
    <Transition.Root show={state.visible} as={Fragment} appear>
      <Dialog
        as='div'
        className='relative z-10'
        onClose={() => {
          if (!state.visible) return

          window.dispatchEvent(new CustomEvent('commandBarClosed'))
          dispatch({ type: 'hide' })
        }}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-50'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-25'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <Dialog.Panel className='mx-auto max-w-2xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all dark:divide-amber-800/20 dark:bg-[#1f1f1f]'>
              <Combobox onChange={handleSelect}>
                <div className='relative'>
                  <MagnifyingGlassIcon
                    className='pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400'
                    aria-hidden='true'
                  />
                  <Combobox.Input
                    id='searchfield'
                    ref={inputReference}
                    className='h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 outline-none ring-0 placeholder:text-gray-400 sm:text-sm dark:text-gray-200'
                    placeholder={
                      isOptionsState(state) ? state.placeholder : 'Search...'
                    }
                    onChange={(event) => debouncedDispatch(event.target.value)}
                    autoComplete='off'
                    autoCorrect='off'
                    spellCheck='false'
                  />
                </div>

                <Combobox.Options
                  static
                  className='max-h-96 scroll-py-2 divide-y divide-gray-100 overflow-y-auto'
                >
                  <li className='p-2'>
                    {isSearchState(state) && state.searchTerm === '' && (
                      <h2 className='mb-2 mt-4 px-3 text-xs font-semibold text-gray-500'>
                        Recently changed
                      </h2>
                    )}
                    <ul className='text-sm text-gray-700 dark:text-gray-300'>
                      {items.map((item) => (
                        <Combobox.Option
                          key={item.id}
                          value={item}
                          className={({ active }) =>
                            classNames(
                              'flex cursor-default select-none items-center rounded-md px-3 py-2',
                              active && 'bg-gray-200 dark:bg-amber-800/20'
                            )
                          }
                        >
                          {({ active }) => (
                            <>
                              {item.icon && (
                                <item.icon
                                  className={classNames(
                                    'h-6 w-6 flex-none',
                                    'text-gray-400'
                                  )}
                                  aria-hidden='true'
                                />
                              )}
                              <span className='ml-3 flex-auto truncate'>
                                {item.title}
                              </span>
                              {active && (
                                <span className='ml-3 flex-none text-gray-400'>
                                  {item.type === 'option'
                                    ? 'Select...'
                                    : 'Jump to...'}
                                </span>
                              )}
                              {item.shortcut && (
                                <span className='ml-3 flex-none text-xs text-gray-400'>
                                  {item.shortcut}
                                </span>
                              )}
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                    </ul>
                  </li>
                </Combobox.Options>
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
