import {
  ReactNode,
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from 'react'
import {
  Note,
  NoteType,
  calendarFilenameToTitle,
  dateToKey,
  filenameToKey,
  findMatches,
  isCalendarNote,
} from '../utils/syncUtils'
import {
  usePrivateCalendarNotes,
  usePrivateProjectNotes,
  useTeamCalendarNotes,
  useTeamProjectNotes,
} from '../hooks/useNotesFactory'
import {
  usePrivateCalendarNotesByDate,
  usePrivateProjectNotesByTitle,
  useTeamCalendarNotesByDate,
  useTeamProjectNotesByTitle,
} from '../hooks/useNotesFactory'
import dayjs from 'dayjs'
import {
  ReferencesContext,
  NoteReferenceMap,
  FromNote,
} from './ReferencesContext'
import { BlockNoteEditor } from '@packages/blocknote-core'
import { useDebounceCallback } from 'usehooks-ts'
import { getTeamSpaceTitle } from '../utils/teamSpace'

// Constants
const REFERENCE_REGEX = /\[\[([^\]]+)]]/g
const SCHEDULE_REGEX =
  /(?<=\s|^)>(\d{4}(?:(?:-\d{2}){1,2}|-(?:W\d{2}|Q[1-4]))|today)\b/g

const DEBOUNCE_DELAY = 500 // Delay in milliseconds for debouncing reference updates

function getDateOrRecordName(note: Note) {
  return isCalendarNote(note.noteType)
    ? filenameToKey(note.filename)
    : note.recordName
}

function generateReferences(
  inputNotes: Map<string, Note>,
  lookUpNotes: Map<string, Map<string, NoteType>>
): NoteReferenceMap {
  const references: NoteReferenceMap = new Map()
  const todayKey = dateToKey(dayjs(), 'day')

  for (const note of inputNotes.values()) {
    assignReferencesFromNote(
      note,
      lookUpNotes,
      todayKey,
      references,
      getTeamSpaceTitle(inputNotes, note)
    )
  }

  return references
}

function assignReferencesFromNote(
  note: Note,
  lookUpNotes: Map<string, Map<string, NoteType>>,
  todayKey: string,
  references: NoteReferenceMap,
  teamSpaceTitle?: string
): NoteReferenceMap {
  const dateOrRecordName = getDateOrRecordName(note)
  if (!dateOrRecordName || !note.content || !note.recordName) {
    return references
  }

  // Important: reset references for note
  for (const incomingReferences of references.values()) {
    incomingReferences.delete(note.recordName)
  }

  // Then build all references for note from new content
  const results = findMatches(note, [
    {
      regex: REFERENCE_REGEX,
      group: 1,
      cleanRegex: undefined,
      replace: '',
    },
    {
      regex: SCHEDULE_REGEX,
      group: 1,
      cleanRegex: undefined,
      replace: '',
    },
  ])

  for (const result of results) {
    const referencedNoteID = result.match === 'today' ? todayKey : result.match
    const referencedNotes =
      lookUpNotes.get(referencedNoteID) ?? new Map<string, NoteType>()

    // Track processed actualReferencedNoteIDs for this result
    const processedNoteIDs = new Set<string>()

    // Case A: referenced notes exist
    for (const [recordName, referencedNoteType] of referencedNotes.entries()) {
      const actualReferencedNoteID = isCalendarNote(referencedNoteType)
        ? referencedNoteID
        : recordName

      // Skip if we've already processed this actualReferencedNoteID
      // This occurs if we have a private note for a specific date, say 2025-01-23, and then again a teamspace note with the same title.
      if (processedNoteIDs.has(actualReferencedNoteID)) {
        continue
      }
      processedNoteIDs.add(actualReferencedNoteID)

      updateReference(
        references,
        note,
        actualReferencedNoteID,
        result.line,
        result.lineIndex,
        teamSpaceTitle
      )
    }

    // Case B: no referenced notes found
    if (referencedNotes.size === 0) {
      updateReference(
        references,
        note,
        referencedNoteID,
        result.line,
        result.lineIndex,
        teamSpaceTitle
      )
    }
  }

  return references
}

function getNoteTitle(note: Note, teamSpaceTitle?: string) {
  const baseTitle = isCalendarNote(note.noteType)
    ? calendarFilenameToTitle(note.filename, true)
    : note.title ?? 'Untitled'
  return teamSpaceTitle ? `${baseTitle} (${teamSpaceTitle})` : baseTitle
}

function updateReference(
  references: NoteReferenceMap,
  referencingNote: Note,
  referencedNoteID: string,
  line: string,
  lineIndex: number,
  teamSpaceTitle?: string
) {
  if (!referencingNote.recordName) {
    return
  }

  let incomingReferences = references.get(referencedNoteID)
  if (!incomingReferences) {
    incomingReferences = new Map<string, FromNote>()
    references.set(referencedNoteID, incomingReferences)
  }

  // Get existing reference or create a new one with proper types
  let fromNote = incomingReferences.get(referencingNote.recordName)
  if (!fromNote) {
    fromNote = {
      title: getNoteTitle(referencingNote, teamSpaceTitle),
      noteType: referencingNote.noteType,
      filename: referencingNote.filename,
      parent: referencingNote.parent ?? undefined, // Convert null to undefined using nullish coalescing
      lines: [],
    }
    incomingReferences.set(referencingNote.recordName, fromNote)
  }

  const block = BlockNoteEditor.notePlanToBlocks(line, '')[0]

  // exclude scheduled tasks
  if (block.props === undefined || !('scheduled' in block.props)) {
    fromNote.lines.push({
      index: lineIndex,
      content: line,
      block: block,
    })
  }

  if (fromNote.lines.length === 0) {
    incomingReferences.delete(referencingNote.recordName)
  }
}

// Helper function to remove references for a deleted note
function removeReferencesForDeletedNote(
  references: NoteReferenceMap,
  deletedNoteID: string
) {
  for (const incomingReferences of references.values()) {
    incomingReferences.delete(deletedNoteID)
  }
  references.delete(deletedNoteID)
}

/**
 * ReferencesProvider Component
 *
 * Algorithm and Flow to Compute References:
 *
 * 1. Initialization:
 *    - Fetches four types of notes: private calendar notes, private project notes, team calendar notes, and team project notes.
 *    - Creates a memoized map of all notes, combining the above four types.
 *    - Creates a memoized lookup map (lookUpNotes) for efficient reference searching.
 *      This map uses dates for calendar notes and titles for project notes as keys.
 *      The value contains only the noteType, not the full note object to save memory.
 *
 * 2. Reference Computation:
 *    - The `generateReferences` function computes references for all notes.
 *      - It iterates through each note in `allNotes` and then assigns references using `assignReferencesFromNote`.
 *    - The `assignReferencesFromNote` function updates the `references` map:
 *      - It resets existing references for the note.
 *      - It finds matches for reference patterns (using `REFERENCE_REGEX` and `SCHEDULE_REGEX`).
 *      - It processes each match found in the note's content.
 *      - For each match, it checks if the referenced note exists in `lookUpNotes`.
 *      - It updates the `references` map with the new reference information, linking the current note to the referenced note as an incoming reference.
 *      - Scheduled tasks are excluded from references.
 *    - References are stored in a `NoteReferenceMap`, where each key is either a date or a recordName or a title,
 *      and the value is a map of incoming note references.
 *
 * 3. Dynamic Updates:
 *    - The `updateReferencesForNote` function updates references for a single note. It's debounced for performance and to reduce UI blocking.
 *    - The component uses `useEffect` to:
 *      - Compute initial references when the component mounts and `allNotes` is populated and ready.
 *      - Detect changes in notes by comparing `recordChangeTag` and update references accordingly.
 *      - Detect deleted notes and remove their references.
 *    - Another `useEffect` is used to update the `todayKey` at midnight and recompute references if necessary.
 *      - A visibility change listener is set up to check for missed updates when the app becomes visible.
 *
 * 4. Memoization and Optimization:
 *    - `useMemo` is used to memoize `allNotes`, `lookUpNotes` to prevent unnecessary re-computations.
 *    - `useCallback` is used to memoize `generateAllReferences` to prevent unnecessary re-renders.
 *    - `useRef` is used to store the previous notes' `recordChangeTags` for detecting changes and the last update day for midnight updates.
 *    - `useDebounceCallback` is used when updating references for a single note to prevent performance issues.
 *
 * 5. Context Provision:
 *    - The computed `references` map is provided to the context `ReferencesContext.Provider`, making it available to all child components.
 *
 * 6. Potential Improvements:
 *    - Refactor generateReferences and assignReferencesFromNote into a pure function, so the result can be memoized
 */
export function ReferencesProvider({ children }: { children: ReactNode }) {
  // Fetching notes data
  const { data: privateCalendarNotes } = usePrivateCalendarNotes()
  const { data: privateProjectNotes } = usePrivateProjectNotes()
  const { data: teamCalendarNotes } = useTeamCalendarNotes()
  const { data: teamProjectNotes } = useTeamProjectNotes()

  // Memoized all notes map
  const allNotes = useMemo(() => {
    return new Map<string, Note>([
      ...(privateCalendarNotes?.map ?? []),
      ...(privateProjectNotes?.map ?? []),
      ...(teamCalendarNotes?.map ?? []),
      ...(teamProjectNotes?.map ?? []),
    ])
  }, [
    privateCalendarNotes,
    privateProjectNotes,
    teamCalendarNotes,
    teamProjectNotes,
  ])

  // Fetching notes by date/title
  const {
    data: privateCalendarNotesByDate,
    isSuccess: isPrivateCalendarNotesByDateSuccess,
  } = usePrivateCalendarNotesByDate()
  const {
    data: privateProjectNotesByTitle,
    isSuccess: isPrivateProjectNotesByTitleSuccess,
  } = usePrivateProjectNotesByTitle()
  const {
    data: teamCalendarNotesByDate,
    isSuccess: isTeamCalendarNotesByDateSuccess,
  } = useTeamCalendarNotesByDate()
  const {
    data: teamProjectNotesByTitle,
    isSuccess: isTeamProjectNotesByTitleSuccess,
  } = useTeamProjectNotesByTitle()
  const lookUpNotesReady =
    isPrivateCalendarNotesByDateSuccess &&
    isPrivateProjectNotesByTitleSuccess &&
    isTeamCalendarNotesByDateSuccess &&
    isTeamProjectNotesByTitleSuccess

  // Memoized lookup map
  const lookUpNotes = useMemo(() => {
    // Only noteType is needed to determine the key for the reference
    // Save memory by not storing the note object
    const noteTypeMap = new Map<string, Map<string, NoteType>>()

    const appendToMap = (sourceMap: Map<string, Note[]> | undefined) => {
      if (!sourceMap) return

      for (const [key, notes] of sourceMap) {
        const existingNotes =
          noteTypeMap.get(key) ?? new Map<string, NoteType>()
        for (const note of notes) {
          if (note.recordName) {
            existingNotes.set(note.recordName, note.noteType)
          }
        }
        noteTypeMap.set(key, existingNotes)
      }
    }

    appendToMap(privateCalendarNotesByDate)
    appendToMap(privateProjectNotesByTitle)
    appendToMap(teamCalendarNotesByDate)
    appendToMap(teamProjectNotesByTitle)

    return noteTypeMap
  }, [
    privateCalendarNotesByDate,
    privateProjectNotesByTitle,
    teamCalendarNotesByDate,
    teamProjectNotesByTitle,
  ])

  // State for references and initialization phase
  const [references, setReferences] = useState<NoteReferenceMap>(new Map())

  // Ref to store the previous notes recordChangeTags for detecting changes
  const previousNotesChangeTags = useRef<Map<string, string | undefined>>(
    new Map()
  ) // recordName -> recordChangeTag

  const lastUpdateDay = useRef(dayjs().startOf('day'))

  // Function to update references for a single note with debouncing
  // useDebouncedCallback ensures that the debounced function is memoized and only changes
  // when its dependencies change. This is similar to useMemo, but more convenient for functions.
  const updateReferencesForNote = useDebounceCallback(
    (note: Note, teamSpaceTitle?: string) => {
      setReferences((previousReferences) => {
        const newReferences = new Map(previousReferences)

        // Recompute references for the specific note
        assignReferencesFromNote(
          note,
          lookUpNotes,
          dateToKey(dayjs(), 'day'),
          newReferences,
          teamSpaceTitle
        )

        if (note.recordName) {
          previousNotesChangeTags.current.set(
            note.recordName,
            note.recordChangeTag
          )
        }

        return newReferences
      })
    },
    DEBOUNCE_DELAY
  )

  const generateAllReferences = useCallback(() => {
    setReferences(generateReferences(allNotes, lookUpNotes))
  }, [allNotes, lookUpNotes])

  // Effect to compute initial references and track changes
  useEffect(() => {
    if (allNotes.size === 0 || !lookUpNotesReady) return

    if (previousNotesChangeTags.current.size === 0) {
      // Compute initial references only once
      generateAllReferences()
      previousNotesChangeTags.current = new Map(
        [...allNotes.entries()].map(([key, note]) => [
          key,
          note.recordChangeTag,
        ])
      )
    } else {
      setReferences((previousReferences) => {
        const newReferences = new Map(previousReferences)
        let referencesWereModified = false
        for (const [recordName] of previousNotesChangeTags.current) {
          if (!allNotes.has(recordName)) {
            removeReferencesForDeletedNote(newReferences, recordName)
            referencesWereModified = true
          }
        }
        return referencesWereModified ? newReferences : previousReferences
      })

      // Detect changes in notes and update references
      for (const [recordName, note] of allNotes.entries()) {
        const previousChangeTag =
          previousNotesChangeTags.current.get(recordName)
        if (previousChangeTag !== note.recordChangeTag) {
          updateReferencesForNote(note, getTeamSpaceTitle(allNotes, note))
        }
      }
    }
    return () => {
      updateReferencesForNote.cancel() // cancel any pending debounce calls
    }
  }, [
    allNotes,
    generateAllReferences,
    lookUpNotes,
    lookUpNotesReady,
    updateReferencesForNote,
  ])

  // Effect to update todayKey at midnight and recompute references if necessary
  useEffect(() => {
    const setMidnightInterval = () => {
      const now = dayjs()
      const midnight = now.add(1, 'day').startOf('day')
      const msUntilMidnight = midnight.diff(now)

      // Set a timeout to trigger at the next midnight
      const timer = setTimeout(() => {
        generateAllReferences()
        lastUpdateDay.current = dayjs().startOf('day')
        // Set an interval to trigger every 24 hours after the first midnight
        const interval = setInterval(
          () => {
            generateAllReferences()
            lastUpdateDay.current = dayjs().startOf('day')
          },
          24 * 60 * 60 * 1000
        )
        return () => {
          clearInterval(interval)
        }
      }, msUntilMidnight)

      return () => {
        clearTimeout(timer)
      }
    }

    const checkForMissedUpdates = () => {
      const currentDay = dayjs().startOf('day')
      if (currentDay.isAfter(lastUpdateDay.current)) {
        generateAllReferences()
        lastUpdateDay.current = currentDay
      }
    }

    // Set up visibility change listener to check for missed updates
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkForMissedUpdates()
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)

    const cleanup = setMidnightInterval()

    return () => {
      cleanup()
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [generateAllReferences])

  return (
    <ReferencesContext.Provider value={references}>
      {children}
    </ReferencesContext.Provider>
  )
}
