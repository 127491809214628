import { useEffect, useState } from 'react'
import { BlockNoteEditor } from '@packages/blocknote-core'
import { BlockNoteView, useBlockNote } from '@packages/blocknote-react'
import { useDarkMode } from '../../providers/DarkModeProvider'

export default function ReadOnlyEditor() {
  const isDarkMode = useDarkMode()
  const loadingText = 'Loading...'
  const [summary, setSummary] = useState(
    sessionStorage.getItem('summary') ?? loadingText
  )

  useEffect(() => {
    const handleSummaryChange = () => {
      const currentSummary = sessionStorage.getItem('summary')
      if (currentSummary && currentSummary !== summary) {
        setTimeout(() => {
          setSummary(currentSummary)
        }, 2000) // 2 seconds delay
        clearInterval(intervalId)
      }
    }

    window.addEventListener('storage', handleSummaryChange)

    const intervalId = setInterval(handleSummaryChange, 1000)

    return () => {
      window.removeEventListener('storage', handleSummaryChange)
      clearInterval(intervalId)
    }
  }, [summary])

  const editor = useBlockNote({
    theme: isDarkMode ? 'dark' : 'light',
    editable: false,
  })

  useEffect(() => {
    const blocks = BlockNoteEditor.notePlanToBlocks(summary, '')

    editor?.replaceBlocks(editor.topLevelBlocks, blocks)
  }, [editor, summary])

  const handleDownload = () => {
    const element = document.createElement('a')
    const file = new Blob([summary], { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = 'meeting_summary.txt'
    document.body.append(element)
    element.click()
    element.remove()
  }

  const handleCopyMarkdown = () => {
    navigator.clipboard
      .writeText(summary)
      .then(() => {
        alert('Content copied as Markdown!')
      })
      .catch((error: unknown) => {
        console.error('Failed to copy:', error)
        alert('Failed to copy content. Please try again.')
      })
  }

  return (
    <>
      <div className='bg-orange-100 py-2'>
        <div className='grid grid-cols-1 items-center gap-2 lg:grid-cols-[1fr_auto_1fr] lg:gap-0'>
          <div className='hidden lg:block'></div>
          <a
            className='cursor-pointer text-center text-gray-900 no-underline dark:text-gray-900'
            href='/'
          >
            Register or sign in to save this summary
          </a>
          <div className='flex justify-center space-x-2 px-2 lg:justify-end'>
            <button
              onClick={handleDownload}
              className='rounded bg-blue-500 px-2 py-1 text-sm font-bold text-white hover:bg-blue-700'
            >
              Download
            </button>
            <button
              onClick={handleCopyMarkdown}
              className='rounded border border-gray-400 px-2 py-1 text-sm text-gray-600 hover:bg-gray-100'
            >
              Copy as Markdown
            </button>
          </div>
        </div>
      </div>
      <div className='note-body flex flex-col'>
        <div className='editor-container-wrapper'>
          <div className='editor-container'>
            <BlockNoteView editor={editor} />
          </div>
        </div>
      </div>
    </>
  )
}
