import {
  InlineContent,
  StyledText,
} from "../../extensions/Blocks/api/inlineContentTypes";
import {
  Block,
  PropSchema,
  Props,
} from "../../extensions/Blocks/api/blockTypes";
import { BlockNoteEditor, DefaultBlockSchema, isFilenameImage } from "../..";

export function convertTaskStatesToMarkdown(prop: Props<PropSchema>, useEmptyBrackets = false): string {
  if (prop.checked === true || prop.checked === "true") {
    return " [x]";
  } else if (prop.cancelled === true || prop.cancelled === "true") {
    return " [-]";
  } else if (prop.scheduled === true || prop.scheduled === "true") {
    return " [>]";
  } else {
    return useEmptyBrackets ? " [ ]" : ""; // Use [ ] when specifically requested
  }
}

export function convertBlockContentToMarkdown(
  content: InlineContent[],
  isLegacyAttachment?: boolean // Used by the CloudKit client only to figure out which asset is for which file link
): string {
  let text = "";
  let contentLength = content.length;
  for (let i = 0; i < contentLength; ++i) {
    let contentItem = content[i];

    switch (contentItem.type) {
      case "text":
        // serialize styles
        if (contentItem.styles.bold) {
          text += "**" + contentItem.text + "**";
        } else if (contentItem.styles.italic) {
          text += "*" + contentItem.text + "*";
        } else if (contentItem.styles.strikethrough) {
          text += "~~" + contentItem.text + "~~";
        } else if (contentItem.styles.highlighted) {
          text += "==" + contentItem.text + "==";
        } else if (contentItem.styles.underlined) {
          text += "~" + contentItem.text + "~";
        } else if (contentItem.styles.code) {
          text += "`" + contentItem.text + "`";
        } else if (contentItem.styles.inlineAttachment) {
          const attrs = contentItem.attrs;
          if (attrs && attrs.filename && attrs.title) {
            let title = attrs.title;
            let filename = attrs.filename;

            // If this is cloudkit == legacy attachment and the title is not already named image or file
            if (isLegacyAttachment && title !== "image" && title !== "file") {
              filename = title;
              title = isFilenameImage(filename, title) ? "image" : "file";
            }

            text += "![" + title + "](" + filename + ")";
          }
        } else {
          text += contentItem.text;
        }
        break;
      case "link":
        const linkContent = convertBlockContentToMarkdown(
          contentItem.content,
          isLegacyAttachment
        );
        if (linkContent === contentItem.href) {
          text += linkContent;
        } else {
          text += "[" + linkContent + "](" + contentItem.href + ")";
        }

        break;
    }
  }

  // Convert linebreaks to softbreaks ("line seperator" characters as opposed to "paragraph seperator")
  return text.replace(/\n/g, "\u2028");
}

export function convertBlockToMarkdown(
  block: Block<DefaultBlockSchema>,
  depth: number,
  bulletItemCharacters: string[],
  taskItemCharacters: string[],
  isLegacyAttachment?: boolean // Used by the CloudKit client only to figure out which asset is for which file link
): string {
  let text = "\t".repeat(depth);
  const bulletItemCharacter = bulletItemCharacters[0] || "";
  const taskItemCharacter = taskItemCharacters[0] || "";
  
  // Import the default todo character from BlockNoteEditor if taskItemCharacter is empty
  const defaultTodoCharacter = taskItemCharacter || BlockNoteEditor.defaultTodoCharacter || "-";
  // serialize block types
  switch (block.type) {
    case "heading":
      text += "#".repeat(parseInt(block.props?.level || "1")) + " ";
      break;
    case "quoteListItem":
      text += "> ";
      break;
    case "bulletListItem":
      text += `${bulletItemCharacter} `;
      break;
    case "numberedListItem":
      text += block.props?.index + ". ";
      break;
    case "taskListItem":
      // When using the defaultTodoCharacter (no taskItemCharacters), always include [ ]
      const useEmptyBrackets = taskItemCharacters.length === 0;
      text +=
        defaultTodoCharacter +
        convertTaskStatesToMarkdown(block.props || {}, useEmptyBrackets) +
        " ";
      break;
    case "checkListItem":
      text += "+" + convertTaskStatesToMarkdown(block.props || {}) + " ";
      break;
    case "tableBlockItem":
      // For each child, call serializeBlock recursively
      if (block.children && block.children.length > 0) {
        const children = block.children;
        const headingRow = children[0];

        // If it's the first row add a row with only "-"s as content after that, then continue
        if (headingRow) {
          text += convertBlockToMarkdown(
            headingRow,
            0,
            bulletItemCharacters,
            taskItemCharacters,
            isLegacyAttachment
          );
          headingRow.children?.forEach((child) => {
            var contentLength = 0;

            // Count the text length of the content of the child
            child.content.forEach((content) => {
              contentLength += (content as StyledText)?.text.length ?? 0;
            });

            // We want for each column as many "-" as the length of the text in the heading
            text += "| " + "-".repeat(Math.max(contentLength, 1)) + " ";
          });

          text += "|\n";
        }

        // Start the forEach with the second child
        children.slice(1).forEach((child) => {
          text += convertBlockToMarkdown(
            child,
            0,
            bulletItemCharacters,
            taskItemCharacters,
            isLegacyAttachment
          );
        });
      }
      return text;
    case "tableCellBlockItem":
    case "tableHeaderCellBlockItem":
      return convertBlockContentToMarkdown(block.content, isLegacyAttachment);
    case "tableRowBlockItem":
      text += "|";
      block.children?.forEach((child) => {
        text +=
          " " +
          convertBlockToMarkdown(
            child,
            0,
            bulletItemCharacters,
            taskItemCharacters,
            isLegacyAttachment
          ) +
          " |";
      });
      return text + "\n";
    case "separator":
      text += "---";
      break;
    case "codefence":
      // Don't save "null" as language
      const language =
        block.props && block.props.language && block.props.language !== "null"
          ? block.props.language
          : "";

      // Dont add the tabs at the beginning, this would make the code block invalid, so overwrite the text in this case
      text = "```" + language + "\n";
      break;
    case "paragraph":
      break;
  }

  // serialize content array with InlineContent
  if (block.type === "codefence") {
    if (!block.content || block.content.length === 0) {
      text += "\n";
    } else {
      text += (block.content[0] as StyledText).text; // We want the raw code, don't process it
    }
  } else {
    text += convertBlockContentToMarkdown(block.content, isLegacyAttachment);
  }

  // append fold marker
  if (block.props?.folded === true || block.props?.folded === "true") {
    text += " …";
  }

  if (block.type === "codefence") {
    text += "\n```";
  }

  // end block with newline
  text += "\n";
  let children = block.children || [];
  let childrenLength = children.length;

  for (let i = 0; i < childrenLength; ++i) {
    text += convertBlockToMarkdown(
      children[i],
      depth + 1,
      bulletItemCharacters,
      taskItemCharacters,
      isLegacyAttachment
    );
  }

  return text;
}

// export function postProcessMarkdown(note: string): string {
//   // adjust numbering of numbered list items according to their level
//   let lines = note.split(/\r?\n/);
//   let linesLength = lines.length;
//   let currentNumber = 0;
//   let previousLevel = 0;
//   for (let i = 0; i < linesLength; ++i) {
//     let line = lines[i];
//     let matches = /^(\s*?)(\d+)\.\s+(.*)/.exec(line);
//     if (matches != null) {
//       let leadingWhitespace = matches[1].length;
//       let level = 0;
//       if (leadingWhitespace > 1) {
//         level = Math.floor(leadingWhitespace / 2);
//       }
//       if (level === previousLevel) {
//         currentNumber++;
//       } else {
//         currentNumber = 1;
//       }
//       lines[i] = matches[1] + currentNumber.toString() + ". " + matches[3];
//       previousLevel = level;
//     }
//   }
//   let text = lines.join("\n");
//   // if there is more than one line and the last line is empty, remove it
//   if (linesLength > 1 && lines[linesLength - 1] === "") {
//     text = text.substring(0, text.length - 1);
//   }
//   return text;
// }

export function convertBlocksToMarkdown(
  blocks: Block<DefaultBlockSchema>[],
  isLegacyAttachment?: boolean // Used by the CloudKit client only to figure out which asset is for which file link
): string {
  const bulletItemCharacters = BlockNoteEditor.bulletItemCharacters;
  const taskItemCharacters = BlockNoteEditor.taskItemCharacters;
  
  let text = "";
  let blocksLength = blocks.length;
  for (let i = 0; i < blocksLength; ++i) {
    text += convertBlockToMarkdown(
      blocks[i],
      0,
      bulletItemCharacters,
      taskItemCharacters,
      isLegacyAttachment
    );
  }

  // Remove trailing newline, because we add it to every block, and it keeps accumulating otherwise
  if (text.length > 0 && text[text.length - 1] === "\n") {
    text = text.substring(0, text.length - 1);
  }

  return text;
  // post process text
  // return postProcessMarkdown(text);
}
