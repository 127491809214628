import { cachedNotesQueryClient } from '../../providers/CachedNotesProvider'
import { type AuthenticatedUser } from '../../utils/User'
import { cacheKeyFromNoteType } from '../../utils/queryKeyFactory'
import { type Note, NoteType } from '../../utils/syncUtils'
import { removeExtension } from '../../utils/syncUtils'
import { SourceDatabase } from '../../utils/syncUtils'
import { ParagraphObject } from './Editor'

/**
 * DataStore type definition
 */
type DataStoreType = {
  /**
   * The currently authenticated user
   */
  currentAuthenticatedUser: AuthenticatedUser | null

  /**
   * Get the preference for the default file (note) extension, such as "txt" or "md".
   * @type {String}
   */
  defaultFileExtension: string

  /**
   * Get all folders as array of strings. Including the root "/". This includes folders that begin with "@" such as "@Archive" and "@Templates". It excludes the trash folder.
   * @type {[String]}
   */
  folders: string[]

  /**
   * Get all calendar notes.
   * @type {[Note]}
   */
  calendarNotes: Note[]

  /**
   * Get all regular, project notes. This includes notes and templates from folders that begin with "@" such as "@Archive" and "@Templates". It excludes notes in the trash folder though.
   * @type {[Note]}
   */
  projectNotes: Note[]

  /**
   * Get all cached hashtags (#tag) that are used across notes. It returns the tags with a leading '#'.
   * @type {[String]}
   */
  hashtags: string[]

  /**
   * Get all cached mentions (@name) that are used across notes. It returns the tags with a leading '@'.
   * @type {[String]}
   */
  mentions: string[]

  /**
   * Get the names of all available filters that can be used in the "Reviews" / "Filters" (renamed) view.
   * Note: Available from v3.6
   * @type {[String]}
   */
  filters: string[]

  /**
   * Returns the value of a given preference.
   * Available keys:
   * "themeLight"              // theme used in light mode
   * "themeDark"               // theme used in dark mode
   * "fontDelta"               // delta to default font size
   * "firstDayOfWeek"          // first day of calendar week
   * "isAgendaVisible"         // only iOS, indicates if the calendar and note below calendar are visible
   * "isAgendaExpanded"        // only iOS, indicates if calendar above note is shown as week (true) or month (false)
   * "isAsteriskTodo"          // "Recognize * as todo" = checked in markdown preferences
   * "isDashTodo"              // "Recognize - as todo" = checked in markdown preferences
   * "isNumbersTodo"           // "Recognize 1. as todo" = checked in markdown preferences
   * "defaultTodoCharacter"    // returns * or -
   * "isAppendScheduleLinks"   // "Append links when scheduling" checked in todo preferences
   * "isAppendCompletionLinks" // "Append completion date" checked in todo preferences
   * "isCopyScheduleGeneralNoteTodos" // "Only add date when scheduling in notes" checked in todo preferences
   * "isSmartMarkdownLink"     // "Smart Markdown Links" checked in markdown preferences
   * "fontSize"                // Font size defined in editor preferences (might be overwritten by custom theme)
   * "fontFamily"              // Font family defined in editor preferences (might be overwritten by custom theme)
   * "isRenderingMarkdown"     // "Render Markdown" in the preferences (means hiding markdown characters).
   * @param {String} key
   * @return {String}
   */
  preference: (key: string) => string | null

  /**
   * Note: Available from NotePlan v3.1+
   * Change a saved preference or create a new one. It will most likely be picked up by NotePlan after a restart, if you use one of the keys utilized by NotePlan.
   * To change a NotePlan preference, use the keys found in the description of the function `.preference(key)`.
   * You can also save custom preferences specific to the plugin, if you need any. Prepend it with the plugin id or similar to avoid collisions with existing keys.
   * Preferences are saved locally and won't be synced.
   * @param {String} key
   * @param {Any} value
   */
  setPreference: (key: string, value: unknown) => void

  /**
   * Returns the calendar note for the given date and timeframe (optional, the default is "day", see below for more options).
   * @param {Date} date
   * @param {String} timeframe - "day" (default), "week", "month", "quarter" or "year"
   * @return {Note}
   */
  calendarNoteByDate: (date: Date, timeframe?: string) => Note

  /**
   * Returns the calendar note for the given date string (can be undefined, if the calendar note was not created yet). See the date formats below for various types of calendar notes:
   * Daily: "YYYYMMDD", example: "20210410"
   * Weekly: "YYYY-Wwn", example: "2022-W24"
   * Quarter: "YYYY-Qq", example: "2022-Q4"
   * Monthly: "YYYY-MM", example: "2022-10"
   * Yearly: "YYYY", example: "2022"
   * @param {String} dateString
   * @return {Note}
   */
  calendarNoteByDateString: (dateString: string) => Note

  /**
   * Returns all regular notes with the given title (first line in editor). Since multiple notes can have the same title, an array is returned.
   * Use 'caseSensitive' (default = false) to search for a note ignoring the case and set 'searchAllFolders' to true if you want to look for notes in trash and archive as well.
   * By default NotePlan won't return notes in trash and archive.
   * @param {String} title
   * @param {Boolean} caseInsensitive
   * @param {Boolean} searchAllFolders
   * @return {[Note]}
   */
  projectNoteByTitle: (
    title: string,
    caseInsensitive?: boolean,
    searchAllFolders?: boolean
  ) => Note[]

  /**
   * Returns all regular notes with the given case insensitive title (first line in editor).
   * Since multiple notes can have the same title, an array is returned.
   * @param {String} title
   * @return {[Note]}
   */
  projectNoteByTitleCaseInsensitive: (title: string) => Note[]

  /**
   * Returns the regular note for the given filename with file-extension, the filename has to include the relative folder such as `folder/filename.txt`.
   * Use no folder if it's in the root (means without leading slash).
   * @param {String} filename
   * @return {Note}
   */
  projectNoteByFilename: (filename: string) => Note

  /**
   * Returns a regular or calendar note for the given filename. Type can be "Notes" or "Calendar".
   * Include relative folder and file extension (`folder/filename.txt` for example).
   * Use "YYYYMMDD.ext" for calendar notes, like "20210503.txt".
   * @param {String} filename
   * @param {String} type
   * @return {Note}
   */
  noteByFilename: (filename: string, type: string) => Note

  /**
   * Note: Available from v3.5.2
   * Returns an array of paragraphs having the same blockID like the given one.
   * You can use `paragraph[0].note` to access the note behind it and make updates via `paragraph[0].note.updateParagraph(paragraph[0])`
   * if you make changes to the content, type, etc (like checking it off as type = "done")
   * If you pass no paragraph as argument this will return all synced lines that are available.
   * @param {ParagraphObject?} paragraph
   * @return {[ParagraphObject]}
   */
  referencedBlocks: (paragraph?: ParagraphObject) => ParagraphObject[]

  /**
   * Move a regular note using the given filename (include extension and relative folder like `folder/filename.txt`,
   * if it's in the root folder don't add a leading slash) to another folder. Use "/" for the root folder as destination.
   * Returns the final filename (if the there is a duplicate, it will add a number).
   * If you want to move a calendar note, use as type "calendar", by default it uses "note" (available from v3.9.3).
   * @param {String} filename
   * @param {String} folder
   * @param {String} type - (default is "note")
   * @return {String}
   */
  moveNote: (filename: string, folder: string, type?: string) => string

  /**
   * Creates a regular note using the given title and folder. Use "/" for the root folder.
   * It will write the given title as "# title" into the new file.
   * Returns the final filename with relative folder (`folder/filename.txt` for example).
   * If there is a duplicate, it will add a number.
   * @param {String} title
   * @param {String} folder
   * @return {String}
   */
  newNote: (title: string, folder: string) => string

  /**
   * Creates a regular note using the given content and folder. Use "/" for the root folder.
   * The content should ideally also include a note title at the top.
   * Returns the final filename with relative folder (`folder/filename.txt` for example).
   * If there is a duplicate, it will add a number.
   * Alternatively, you can also define the filename as the third optional variable (v3.5.2+)
   * Note: Available from v3.5
   * @param {String} content
   * @param {String} folder
   * @param {String} filename - (optional)
   * @return {String}
   */
  newNoteWithContent: (
    content: string,
    folder: string,
    filename?: string
  ) => string

  /**
   * Save a JavaScript object as JSON file.
   * @param {Object} object
   * @param {String?} filename
   * @return {Boolean}
   */
  saveJSON: (object: unknown, filename?: string) => boolean

  /**
   * Load a JavaScript object from a JSON file.
   * @param {String?} filename
   * @return {Object?}
   */
  loadJSON: (filename?: string) => unknown

  /**
   * Loads the plugin related settings as a JavaScript object.
   * @return {Object?}
   */
  settings: unknown

  /**
   * Save data to a file, as base64 string.
   * @param {String} data
   * @param {String} filename
   * @param {Boolean} saveAsString
   * @return {Boolean}
   */
  saveData: (data: string, filename: string, saveAsString: boolean) => boolean

  /**
   * Load binary data from file encoded as base64 string.
   * @param {String} filename
   * @param {Boolean} loadAsString
   * @return {String?}
   */
  loadData: (filename: string, loadAsString: boolean) => string | null

  /**
   * Checks the existence of a file in the data folder.
   * @param {String} filename
   * @return {Boolean}
   */
  fileExists: (filename: string) => boolean

  /**
   * Loads all available plugins asynchronously from the GitHub repository.
   * @param {Boolean} showLoading
   * @param {Boolean} showHidden
   * @param {Boolean} skipMatchingLocalPlugins
   * @return {Promise}
   */
  listPlugins: (
    showLoading: boolean,
    showHidden: boolean,
    skipMatchingLocalPlugins: boolean
  ) => Promise<unknown[]>

  /**
   * Installs a given plugin.
   * @param {PluginObject} pluginObject
   * @param {Boolean} showLoading
   * @return {Promise}
   */
  installPlugin: (pluginObject: unknown, showLoading: boolean) => Promise<void>

  /**
   * Returns all installed plugins as PluginObject(s).
   * @return {[PluginObject]}
   */
  installedPlugins: () => unknown[]

  /**
   * Checks if the given pluginID is installed or not.
   * @param {String} pluginID
   * @return {Boolean}
   */
  isPluginInstalledByID: (pluginID: string) => boolean

  /**
   * Installs a given array of pluginIDs if needed.
   * @param {[String]} pluginIDs
   * @param {Boolean} showPromptIfSuccessful
   * @param {Boolean} showProgressPrompt
   * @param {Boolean} showFailedPrompt
   * @return {Promise}
   */
  installOrUpdatePluginsByID: (
    pluginIDs: string[],
    showPromptIfSuccessful?: boolean,
    showProgressPrompt?: boolean,
    showFailedPrompt?: boolean
  ) => Promise<unknown>

  /**
   * Invoke a given command from a plugin.
   * @param {PluginCommandObject} command
   * @param {[Object]} args
   * @return {Promise}
   */
  invokePluginCommand: (
    command: unknown,
    arguments_: unknown[]
  ) => Promise<unknown>

  /**
   * Invoke a given command from a plugin using the name and plugin ID.
   * @param {String} command
   * @param {String} pluginId
   * @param {[Object]} args
   * @return {Promise}
   */
  invokePluginCommandByName: (
    command: string,
    pluginId: string,
    arguments_: unknown[]
  ) => Promise<unknown>

  /**
   * Searches all notes for a keyword in multiple threads.
   * @param {String} keyword
   * @param {[String]?} types
   * @param {[String]?} inFolders
   * @param {[String]?} notInFolders
   * @param {Boolean} shouldLoadDatedTodos
   * @return {Promise}
   */
  search: (
    keyword: string,
    types?: string[],
    inFolders?: string[],
    notInFolders?: string[],
    shouldLoadDatedTodos?: boolean
  ) => Promise<unknown[]>

  /**
   * Searches all project notes for a keyword.
   * @param {String} keyword
   * @param {[String]?} inFolders
   * @param {[String]?} notInFolders
   * @return {Promise}
   */
  searchProjectNotes: (
    keyword: string,
    inFolders?: string[],
    notInFolders?: string[]
  ) => Promise<unknown[]>

  /**
   * Searches all calendar notes for a keyword.
   * @param {String} keyword
   * @param {Boolean} shouldLoadDatedTodos
   * @return {Promise}
   */
  searchCalendarNotes: (
    keyword: string,
    shouldLoadDatedTodos?: boolean
  ) => Promise<unknown[]>

  /**
   * Updates the cache, so you can access changes faster.
   * @param {Note} note
   * @param {Boolean} shouldUpdateTags
   * @return {Note}
   */
  updateCache: (note: unknown, shouldUpdateTags: boolean) => unknown

  /**
   * Creates a folder if it doesn't exist yet.
   * @param {String} folderPath
   * @return {Boolean}
   */
  createFolder: (folderPath: string) => boolean

  /**
   * Returns all overdue tasks.
   * @return {Promise}
   */
  listOverdueTasks: () => Promise<unknown[]>

  /**
   * Sets the currently authenticated user
   * @param {AuthenticatedUser | null} user
   */
  setAuthenticatedUser: (user: AuthenticatedUser | null) => void

  /**
   * Gets the currently authenticated user
   * @return {AuthenticatedUser | null}
   */
  getAuthenticatedUser: () => AuthenticatedUser | null
}

/**
 * Builds the full path for a note by traversing its parent chain
 */
function buildFullPath(note: Note, notesMap: Map<string, Note>): string {
  // Return original filename for non-Supabase notes or notes without parent
  if (note.source !== SourceDatabase.SUPABASE || !note.parent) {
    return note.filename
  }

  // Build path from parent chain
  let currentNote: Note | undefined = note
  const pathParts: string[] = []

  while (currentNote) {
    pathParts.unshift(currentNote.filename)
    currentNote = currentNote.parent
      ? notesMap.get(currentNote.parent)
      : undefined
  }

  return pathParts.join('/')
}

/**
 * DataStore is a singleton that provides a mock implementation of the DataStoreType.
 * Partially implemented:
 * - Make use of cachedNotesQueryClient to get the cached notes.
 */
const DataStore: DataStoreType = {
  // eslint-disable-next-line unicorn/no-null
  currentAuthenticatedUser: null,
  defaultFileExtension: 'txt',
  folders: ['/', '@Archive', '@Templates'],
  calendarNotes: [{ title: 'Meeting Notes', date: new Date() }],
  get projectNotes() {
    const user = this.currentAuthenticatedUser
    if (!user) return []

    const cacheKey = cacheKeyFromNoteType(NoteType.PROJECT_NOTE, user)
    const notesCache = cachedNotesQueryClient.getQueryData<{
      map: Map<string, Note>
    }>(cacheKey)

    if (!notesCache?.map) return []

    // Convert the map to an array and process each note
    return (
      [...notesCache.map.values()]
        .filter((note) => !note.isFolder)
        // .filter((note) => !note.content?.includes('<%')) // TODO: Remove this once dynamic templates are implemented
        .map((note) => ({
          ...note,
          filename: buildFullPath(note, notesCache.map),
          frontmatterTypes: note.frontmatterTypes ?? [],
        }))
    )
  },
  hashtags: ['#work', '#personal'],
  mentions: ['@john', '@jane'],
  filters: ['filter1', 'filter2'],

  preference: (key: string) => {
    const preferences = {
      themeLight: 'light',
      themeDark: 'dark',
      fontDelta: '1',
      firstDayOfWeek: 'Monday',
    }
    return preferences[key] || null
  },

  setPreference: (key: string, value: unknown) => {
    console.log(`Set preference ${key} to ${value}`)
  },

  calendarNoteByDate: (date: Date, timeframe = 'day') => {
    return { title: 'Daily Note', date }
  },

  calendarNoteByDateString: (dateString: string) => {
    return { title: `Note for ${dateString}` }
  },

  projectNoteByTitle: (
    title: string,
    caseInsensitive = false,
    _searchAllFolders = false
  ) => {
    const user = DataStore.currentAuthenticatedUser
    if (!user) return []

    const cacheKey = cacheKeyFromNoteType(NoteType.PROJECT_NOTE, user)
    const notesCache = cachedNotesQueryClient.getQueryData<{
      map: Map<string, Note>
    }>(cacheKey)

    if (!notesCache?.map) return []

    const notes = [...notesCache.map.values()]
    const filteredNotes = caseInsensitive
      ? notes.filter((note) =>
          note.title?.toLowerCase().includes(title.toLowerCase())
        )
      : notes.filter((note) => note.title === title)

    return filteredNotes
  },

  projectNoteByTitleCaseInsensitive: (title: string) => {
    return [{ title }]
  },

  projectNoteByFilename: (filename: string) => {
    const user = DataStore.currentAuthenticatedUser
    if (!user) return

    const cacheKey = cacheKeyFromNoteType(NoteType.PROJECT_NOTE, user)
    const notesCache = cachedNotesQueryClient.getQueryData<{
      map: Map<string, Note>
    }>(cacheKey)

    if (!notesCache?.map) return

    // Build a map of full paths to notes
    const fullPathMap = new Map<string, Note>()

    for (const note of notesCache.map.values()) {
      // filter out untitled notes
      if (note.filename === '' || note.filename.toLowerCase() === 'untitled') {
        continue
      }

      const fullPath = buildFullPath(note, notesCache.map)
      fullPathMap.set(fullPath, note)
    }

    // Try to find the note by exact match first
    const matchingNote = fullPathMap.get(filename)
    if (matchingNote) return matchingNote

    // If no exact match, try matching without extensions
    const filenameNoExtension = removeExtension(filename)
    return fullPathMap.get(filenameNoExtension)
  },

  noteByFilename: (filename: string, type: string) => {
    return { filename, type }
  },

  referencedBlocks: (paragraph?: ParagraphObject) => {
    return [{ content: 'Sample paragraph' }]
  },

  moveNote: (filename: string, folder: string, type = 'note') => {
    return `${folder}/${filename}`
  },

  newNote: (title: string, folder: string) => {
    return `${folder}/${title}.txt`
  },

  newNoteWithContent: (content: string, folder: string, filename?: string) => {
    return `${folder}/${filename || 'newNote'}.txt`
  },

  saveJSON: (object: unknown, filename?: string) => {
    console.log(`Saved JSON to ${filename || 'settings.json'}`)
    return true
  },

  loadJSON: (filename?: string) => {
    return { key: 'value' }
  },

  settings: { settingKey: 'settingValue' },

  saveData: (data: string, filename: string, saveAsString: boolean) => {
    console.log(`Saved data to ${filename}`)
    return true
  },

  loadData: (filename: string, loadAsString: boolean) => {
    return 'mock data'
  },

  fileExists: (filename: string) => {
    return true
  },

  listPlugins: async (
    showLoading: boolean,
    showHidden: boolean,
    skipMatchingLocalPlugins: boolean
  ) => {
    return [{ name: 'Plugin1' }, { name: 'Plugin2' }]
  },

  installPlugin: async (pluginObject: any, showLoading: boolean) => {
    console.log(`Installed plugin ${pluginObject.name}`)
  },

  installedPlugins: () => {
    return [{ name: 'InstalledPlugin1' }]
  },

  isPluginInstalledByID: (pluginID: string) => {
    return true
  },

  installOrUpdatePluginsByID: async (
    pluginIDs: string[],
    showPromptIfSuccessful?: boolean,
    showProgressPrompt?: boolean,
    showFailedPrompt?: boolean
  ) => {
    return { code: 0, message: 'Success' }
  },

  invokePluginCommand: async (command: unknown, arguments_: unknown[]) => {
    return 'Command result'
  },

  invokePluginCommandByName: async (
    command: string,
    pluginId: string,
    arguments_: unknown[]
  ) => {
    return 'Command result'
  },

  search: async (
    keyword: string,
    types?: string[],
    inFolders?: string[],
    notInFolders?: string[],
    shouldLoadDatedTodos?: boolean
  ) => {
    return [{ content: 'Search result' }]
  },

  searchProjectNotes: async (
    keyword: string,
    inFolders?: string[],
    notInFolders?: string[]
  ) => {
    return [{ content: 'Project note search result' }]
  },

  searchCalendarNotes: async (
    keyword: string,
    shouldLoadDatedTodos?: boolean
  ) => {
    return [{ content: 'Calendar note search result' }]
  },

  updateCache: (note: unknown, shouldUpdateTags: boolean) => {
    return note
  },

  createFolder: (folderPath: string) => {
    console.log(`Created folder ${folderPath}`)
    return true
  },

  listOverdueTasks: async () => {
    return [{ content: 'Overdue task' }]
  },

  setAuthenticatedUser: (user: AuthenticatedUser | null) => {
    DataStore.currentAuthenticatedUser = user
  },

  getAuthenticatedUser: () => {
    return DataStore.currentAuthenticatedUser
  },
}

declare global {
  interface Window {
    DataStore: DataStoreType
  }
}

window.DataStore = DataStore

export default DataStore
