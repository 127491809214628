import { CommandBarSelectedEvent } from '../../modules/command-bar/types'

type CommandBarResultObject = {
  value: string
  index: number
}

type CommandBarType = {
  /**
   * Get or set the current text input placeholder (what you can read when no input is typed in) of the Command Bar.
   * @type {String}
   */
  placeholder: string

  /**
   * Get the current text input content of the Command Bar (what the user normally types in).
   * @type {String}
   */
  searchText: string

  /**
   * Hides the Command Bar
   */
  hide: () => void

  /**
   * Display an array of choices as a list (only strings) which the user can "fuzzy-search" filter by typing something.
   * The user selection is returned as a Promise. So use it with "await CommandBar.showOptions(...)".
   * The result is a CommandBarResultObject (as Promise success result), which has ".value" and ".index".
   * It only supports a string array as input for the options, so you might need to map your list first to [string].
   * Use the ".index" attribute to refer back to the selected item in the original array.
   * If you want to provide an existing search text that will be inserted into the command bar, use the third variable.
   * @param {[String]} options
   * @param {String} placeholder
   * @param {String} searchText
   * @return {Promise (CommandBarResultObject)}
   */
  showOptions: (
    options: string[],
    placeholder: string,
    searchText: string
  ) => Promise<CommandBarResultObject>

  /**
   * Asks the user to enter something into the CommandBar.
   * Use the "placeholder" value to display a question, like "Type the name of the task".
   * Use the "submitText" to describe what happens with the selection, like "Create task named '%@'".
   * The "submitText" value supports the variable "%@" in the string, that NotePlan autofills with the typed text.
   * It returns a Promise, so you can wait (using "await...") for the user input with the entered text as success result.
   * If you want to provide an existing search text that will be inserted into the command bar, use the third variable.
   * @param {String} placeholder
   * @param {String} submitText
   * @param {String} searchText
   * @return {Promise (String)}
   */
  showInput: (
    placeholder: string,
    submitText: string,
    searchText: string
  ) => Promise<string>

  /**
   * Note: Available from v3.0.25
   * Shows or hides a window with a loading indicator or a progress ring (if progress is defined) and an info text (optional).
   * `text` is optional, if you define it, it will be shown below the loading indicator.
   * `progress` is also optional. If it's defined, the loading indicator will change into a progress ring. Use float numbers from 0-1 to define how much the ring is filled.
   * When you are done, call `showLoading(false)` to hide the window. See an example function in the example section below.
   * @param {Bool} visible
   * @param {String?} text
   * @param {Float?} progress
   */
  showLoading: (visible: boolean, text?: string, progress?: number) => void

  /**
   * Note: Available from v3.0.25
   * If you call this, anything after `await CommandBar.onAsyncThread()` will run on an asynchronous thread.
   * Use this together with `showLoading`, so that the work you do is not blocking the user interface.
   * Otherwise the loading window will be also blocked.
   *
   * Warning: Don't use any user interface calls (other than showLoading) on an asynchronous thread. The app might crash.
   * You need to return to the main thread before you change anything in the window (such as Editor functions do).
   * Do not call any functions from Editor.* on an async thread.
   * Use `onMainThread()` to return to the main thread. See an example function in the example section below.
   * @return {Promise}
   */
  onAsyncThread: () => Promise<void>

  /**
   * Note: Available from v3.0.25
   * If you call this, anything after `await CommandBar.onMainThread()` will run on the main thread.
   * Call this after `onAsyncThread`, once your background work is done.
   * It is safe to call Editor and other user interface functions on the main thread.
   * See an example function in the example section below.
   * @return {Promise}
   */
  onMainThread: () => Promise<void>

  /**
   * Note: Available from v3.3.2
   * Show a native prompt to the user with a title and a message text. Define at least one button for the user to select (the parameter is an array of strings, each string the title of a button). If you don't supply any buttons, an "OK" button will be displayed. The promise returns as value the pressed button index (i.e. "0" would be the first supplied button).
   * @param {String} title
   * @param {String} message
   * @param {[String]?} buttons
   * @return {Promise<Int>}
   */
  prompt: (
    title: string,
    message: string,
    buttons?: string[]
  ) => Promise<number>

  /**
   * Note: Available from v3.3.2
   * Show a native text input prompt to the user with a title and a message text. The buttons will be automatically "OK" and "Cancel". You can supply a default text which will be prefilled. If the user hits "Cancel", the promise returns false.
   * @param {String} title
   * @param {String} message
   * @param {String?} defaultText
   * @return {Promise<Bool | String>}
   */
  textPrompt: (
    title: string,
    message: string,
    defaultText?: string
  ) => Promise<boolean | string>
}

const CommandBar: CommandBarType = {
  placeholder: 'Type a command...',
  searchText: '',

  hide: () => {
    window.dispatchEvent(new CustomEvent('hideCommandBar'))
  },

  showOptions: async (
    options: string[],
    placeholder: string,
    searchText = ''
  ) => {
    return new Promise<CommandBarResultObject>((resolve, reject) => {
      // Create event listener for option selection
      const handleOptionSelected = (event: CommandBarSelectedEvent) => {
        window.removeEventListener(
          'commandBarOptionSelected',
          handleOptionSelected as EventListener
        )
        window.removeEventListener('commandBarClosed', handleClose)
        resolve({
          value: event.detail.value,
          index: event.detail.index,
        })
      }

      // Create event listener for command bar closing
      const handleClose = () => {
        window.removeEventListener(
          'commandBarOptionSelected',
          handleOptionSelected as EventListener
        )
        window.removeEventListener('commandBarClosed', handleClose)
        reject(new Error('Nothing selected'))
      }

      // Listen for option selection and closing
      window.addEventListener(
        'commandBarOptionSelected',
        handleOptionSelected as EventListener
      )
      window.addEventListener('commandBarClosed', handleClose)

      // Show command bar with options
      if (
        placeholder.toLowerCase().includes('template') &&
        options.length === 0
      ) {
        window.alert('No templates found in the @Templates folder')
      } else {
        window.dispatchEvent(
          new CustomEvent('showCommandBarOptions', {
            detail: {
              options,
              placeholder,
              searchText,
            },
          })
        )
      }
    })
  },

  showInput: async (
    placeholder: string,
    submitText: string,
    searchText: string
  ) => {
    console.log('Showing input:', { placeholder, submitText, searchText })
    return 'Mock user input'
  },

  showLoading: (visible: boolean, text?: string, progress?: number) => {
    console.log('Loading:', { visible, text, progress })
  },

  onAsyncThread: async () => {
    console.log('Switched to async thread')
  },

  onMainThread: async () => {
    console.log('Switched to main thread')
  },

  prompt: async (title: string, message: string, buttons?: string[]) => {
    console.log('Showing prompt:', { title, message, buttons })
    return 0
  },

  textPrompt: async (title: string, message: string, defaultText?: string) => {
    console.log('Showing text prompt:', { title, message, defaultText })
    return 'Mock user input'
  },
}

declare global {
  interface Window {
    CommandBar: CommandBarType
  }
}

window.CommandBar = CommandBar

export default CommandBar
