import { useEffect } from 'react'
import { useAuthenticatedUser } from '../providers/UserProvider'
import DataStore from '../plugins/api/DataStore'

export function useSyncAuthenticatedUserToDataStore() {
  const user = useAuthenticatedUser()

  useEffect(() => {
    DataStore.setAuthenticatedUser(user)
  }, [user])
}
