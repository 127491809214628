// hooks/useSearchResults.ts
import { useMemo, useRef } from 'react'
import MiniSearch from 'minisearch'
import {
  Note,
  NoteType,
  calendarFilenameToTitle,
  isCalendarNote,
} from '../../../utils/syncUtils'

import type { SearchResult as MiniSearchResult } from 'minisearch'

export type SearchResult = {
  id: string
  filename?: string
  noteType?: NoteType
  name: string
  modified: number
  tags: string[]
  type: 'tag' | 'note'
} & Pick<MiniSearchResult, 'terms' | 'queryTerms' | 'score' | 'match'>

export const useSearchResults = (
  notes: Note[],
  query: string,
  openSearch: string
) => {
  const term = (query.length === 0 ? openSearch : query)
    .trim()
    .toLocaleLowerCase()

  const isTagQuery = useMemo(
    () =>
      term.startsWith('hashtag:') ||
      term.startsWith('mention:') ||
      term.startsWith('tag:'),
    [term]
  )

  const typeFilter = useMemo(
    () =>
      isTagQuery || term.startsWith('#') || term.startsWith('@')
        ? [
            NoteType.PROJECT_NOTE,
            NoteType.ASSET_PROJECT_NOTE,
            NoteType.CALENDAR_NOTE,
            NoteType.ASSET_CALENDAR_NOTE,
            NoteType.TEAM_SPACE_NOTE,
            NoteType.TEAM_SPACE_CALENDAR_NOTE,
          ]
        : [
            NoteType.PROJECT_NOTE,
            NoteType.ASSET_PROJECT_NOTE,
            NoteType.TEAM_SPACE_NOTE,
          ],
    [isTagQuery, term]
  )

  const eligibleNotes = useMemo(
    () =>
      notes
        .filter(
          (note) =>
            typeFilter.includes(note.noteType) &&
            !note.isFolder &&
            !note.filename.startsWith('@')
        )
        .map((note) => ({
          id: note.recordName,
          name: isCalendarNote(note.noteType)
            ? calendarFilenameToTitle(note.filename, true)
            : note.title ?? note.filename,
          modified: (note.fileModifiedAt ?? new Date()).getTime(),
          tags: note.tags ?? [],
          type: 'note' as const,
          filename: note.filename,
          noteType: note.noteType,
        })) as SearchResult[],
    [notes, typeFilter]
  )

  const allTagsAndMentions = useMemo(
    () =>
      [...new Set(eligibleNotes.flatMap((note) => note.tags))]
        .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
        .map((tag: string) => ({
          id: tag,
          name: tag,
          modified: Date.now(),
          tags: [],
          type: 'tag' as const,
        })),
    [eligibleNotes]
  )

  const recent = useMemo(
    () => eligibleNotes.sort((a, b) => b.modified - a.modified).slice(0, 100),
    [eligibleNotes]
  )

  // Keep search index in a ref
  const indexReference = useRef<MiniSearch | null>(null)

  // Build search index when eligible notes change
  useMemo(() => {
    const index = new MiniSearch({
      fields: ['name'],
      storeFields: [
        'id',
        'name',
        'filename',
        'noteType',
        'modified',
        'tags',
        'type',
      ],
      searchOptions: {
        fuzzy: 0.2,
        prefix: true,
      },
    })

    // Add all notes to index with full SearchResult data
    const documents = eligibleNotes.map((note) => ({
      ...note,
      type: 'note' as const,
    }))
    index.addAll(documents)
    indexReference.current = index
  }, [eligibleNotes])

  const filteredResults = useMemo(() => {
    if (term.length > 0) {
      if (term.startsWith('#') || term.startsWith('@')) {
        return allTagsAndMentions.filter((tag) =>
          tag.name.toLowerCase().includes(term)
        )
      }

      if (isTagQuery) {
        const searchTag = term
          .replace(/^(hashtag:|mention:|tag:)/, '')
          .trim()
          .toLowerCase()
        return eligibleNotes.filter((note) =>
          note.tags.map((tag) => tag.toLowerCase()).includes(searchTag)
        )
      }

      if (term.length <= 2) {
        return []
      }

      // Use MiniSearch for fuzzy search
      const results = (indexReference.current?.search(term.slice(0, 100), {}) ??
        []) as SearchResult[]
      return results.slice(0, 250)
    }
    return []
  }, [term, allTagsAndMentions, eligibleNotes, isTagQuery])

  return { recent, filteredResults }
}
