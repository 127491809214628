type Environment = {
  /**
   * Note: Available from v3.3.2
   * Returns the environment information:
   * @type {String?}
   */
  languageCode?: string
  regionCode?: string
  is12hFormat: boolean
  preferredLanguages: string[]
  secondsFromGMT: number
  localTimeZoneAbbreviation: string
  localTimeZoneIdentifier: string
  isDaylightSavingTime: boolean
  daylightSavingTimeOffset: number
  nextDaylightSavingTimeTransition: Date
  platform: 'macOS' | 'iPadOS' | 'iOS'
  hasSettings: boolean
  version: string
  versionNumber: number
  buildVersion: number
  templateFolder: string
  machineName: string
  screenWidth: number
  screenHeight: number
}

type RangeObject = {
  start: number
  end: number
  text: string
}

type Editor = {
  id: number
  customID: string
}

type Window = {
  id: number
  customID: string
}

type NotePlanType = {
  environment: Environment

  /**
   * Note: Available from v3.15.1
   * This is an async function, use it with "await". Sends a prompt to OpenAI and returns the result.
   * Optionally send the content of notes as well to process by specifying them in the list 'filenames', which is an array. For example ["note1.md", "folder/note2.md"]. This needs to be the exact path to the note. Your note extension might differ, the default is .txt, if you haven't changed it.
   * For calendar notes, you can use YYYYMMDD.md, like 20241101.md, or 2024-W10.md for weeks, etc. Natural language input is also supported like "this week", "today", "tomorrow", "this month", "next year", etc.
   * @param {String} prompt
   * @param {String[]} filenames
   * @param {Boolean} useStrictFilenames
   * @return {Promise<String>}
   */
  ai: (
    prompt: string,
    filenames: string[],
    useStrictFilenames: boolean
  ) => Promise<string>

  /**
   * Note: Available from v3.5
   * If a folder is selected in the sidebar on Mac, it returns the folder name as string, if something else is selected it returns nil.
   * @return {String?}
   */
  selectedSidebarFolder: string | null

  /**
   * Note: Available from v3.3.2
   * Opens the configuration view for the currently executing plugin. If no settings are available in the plugin.json, the promise will fail.
   * As of 3.3.2 this is only available on macOS. You can check if this particular plugin has settings and if the platform is macOS using the environment variable.
   * See the examples section for more.
   * @return {Promise}
   */
  showConfigurationView: () => Promise<void>

  /**
   * Note: Available from v3.5
   * Reloads the cached files and rebuilds the sidebar. Use it in case there are inconsistencies in the sidebar.
   */
  resetCaches: () => void

  /**
   * Note: Available from v3.5.2
   * Opens the given URL using the default browser (x-callback-urls can also be triggered with this).
   */
  openURL: (_url: string) => void

  /**
   * Note: Available from v3.7.2
   * Returns the ranges that have changed between the two versions.
   * @param {String} version1
   * @param {String} version2
   * @return {[RangeObject]}
   */
  stringDiff: (version1: string, version2: string) => RangeObject[]

  /**
   * Note: Available from v3.8.1
   * Returns a list of all opened editors (in the main view, in split views and in floating windows). See more details in the "Editor" documentation.
   * @return {[Editor]}
   */
  editors: Editor[]

  /**
   * Note: Available from v3.8.1
   * Returns a list of all opened HTML windows.
   * An HTML window has the same window functions like an editor: focus(), close(), id { get }, customID { get set }
   * @return {[Window]}
   */
  htmlWindows: Window[]
}

const NotePlan: NotePlanType = {
  environment: {
    languageCode: 'en',
    regionCode: 'US',
    is12hFormat: true,
    preferredLanguages: ['en-US'],
    secondsFromGMT: -7200,
    localTimeZoneAbbreviation: 'PDT',
    localTimeZoneIdentifier: 'America/Los_Angeles',
    isDaylightSavingTime: true,
    daylightSavingTimeOffset: 3600,
    nextDaylightSavingTimeTransition: new Date(),
    platform: 'macOS',
    hasSettings: true,
    version: '3.8.1',
    versionNumber: 341,
    buildVersion: 876,
    templateFolder: '@Templates',
    machineName: 'macbook-pro.local',
    screenWidth: 1440,
    screenHeight: 900,
  },

  ai: async (
    _prompt: string,
    _filenames: string[],
    _useStrictFilenames: boolean
  ) => {
    await Promise.resolve()
    return 'AI Prompt not supported'
  },

  selectedSidebarFolder: '@Templates',

  showConfigurationView: async () => {
    await Promise.resolve()
  },

  resetCaches: () => {
    // Reset caches implementation
  },

  openURL: (_url: string) => {
    // Open URL implementation
  },

  stringDiff: (_version1: string, _version2: string) => {
    return [{ start: 0, end: 1, text: 'diff' }]
  },

  editors: [{ id: 1, customID: 'editor1' }],

  htmlWindows: [{ id: 1, customID: 'window1' }],
}

declare global {
  interface Window {
    NotePlan: NotePlanType
  }
}

window.NotePlan = NotePlan

export default NotePlan
