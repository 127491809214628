type DateRangeObject = {
  /**
   * The start date of the parsed date text.
   * @type {Date}
   */
  start: Date

  /**
   * The end date of the parsed date text. This might not be defined in the date text. Then the end date = start date.
   * If two time or dates are mentioned in the input string of `Calendar.parseDateText(...)`, then the start and end dates will have the respective times and dates set.
   * @type {Date}
   */
  end: Date

  /**
   * The detected date text. You can use this to remove the date from the original string
   * @type {String}
   */
  text: string

  /**
   * The index where the date text was found in the given string. Use this so you know where the date text started and get the length from `.text` to cut it out of the original string if needed.
   * @type {Integer}
   */
  index: number
}

type CalendarItem = {
  id?: string
  title?: string
  date?: Date
}

type CalendarType = {
  /**
   * Get all available date units: "year", "month", "day", "hour", "minute", "second"
   * @type {[String]}
   */
  dateUnits: string[]

  /**
   * Adds an event or reminder based on the given CalendarItem. Use `CalendarItem.create(...)` to create the event or reminder first.
   * Returns the created CalendarItem with the assigned id, so you can reference it later. If it failed, undefined is returned.
   * @param {CalendarItem} calendarItem
   * @return {CalendarItem}
   */
  add: (calendarItem: CalendarItem) => CalendarItem | undefined

  /**
   * Note: Available from v3.0.26
   * Updates an event or reminder based on the given CalendarItem, which needs to have an ID. A CalendarItem has an ID, when you have used `.add(...)` and saved the return value or when you query the event using `eventsBetween(...)`, `remindersBetween(...)`, `eventByID(...)`, `reminderByID(...)`, etc.
   * Returns a promise, because it needs to fetch the original event objects first in the background, then updates it. Use it with `await`.
   * @param {CalendarItem} calendarItem
   * @return {Promise}
   */
  update: (calendarItem: CalendarItem) => Promise<void>

  /**
   * Note: Available from v3.0.26
   * Removes an event or reminder based on the given CalendarItem, which needs to have an ID. A CalendarItem has an ID, when you have used `.add(...)` and saved the return value or when you query the event using `eventsBetween(...)`, `remindersBetween(...)`, `eventByID(...)`, `reminderByID(...)`, etc.
   * Returns a promise, because it needs to fetch the original event objects first in the background, then updates it. Use it with `await`.
   * @param {CalendarItem} calendarItem
   * @return {Promise}
   */
  remove: (calendarItem: CalendarItem) => Promise<void>

  /**
   * Note: Available from v3.0.25
   * Returns all events between the `startDate` and `endDate`. Use `filter` to search for specific events (keyword in the title).
   * This function fetches events asynchronously, so use async/await.
   * @param {Date} startDate
   * @param {Date} endDate
   * @param {String?} filter
   * @return {Promise([CalendarItem])}
   */
  eventsBetween: (
    startDate: Date,
    endDate: Date,
    filter?: string
  ) => Promise<CalendarItem[]>

  /**
   * Note: Available from v3.0.25
   * Returns all reminders between the `startDate` and `endDate`. Use `filter` to search for specific reminders (keyword in the title).
   * This function fetches reminders asynchronously, so use async/await.
   * @param {Date} startDate
   * @param {Date} endDate
   * @param {String?} filter
   * @return {Promise([CalendarItem])}
   */
  remindersBetween: (
    startDate: Date,
    endDate: Date,
    filter?: string
  ) => Promise<CalendarItem[]>

  /**
   * Note: Available from v3.0.26
   * Returns the event by the given ID. You can get the ID from a CalendarItem, which you got from using `.add(...)` (the return value is a CalendarItem with ID) or when you query the event using `eventsBetween(...)`, `eventByID(...)`, etc.
   * This function fetches the event asynchronously, so use async/await.
   * @param {String} id
   * @return {Promise(CalendarItem)}
   */
  eventByID: (id: string) => Promise<CalendarItem>

  /**
   * Note: Available from v3.0.26
   * Returns the reminder by the given ID. You can get the ID from a CalendarItem, which you got from using `.add(...)` (the return value is a CalendarItem with ID) or when you query the event using `remindersBetween(...)`, `reminderByID(...)`, etc.
   * This function fetches reminders asynchronously, so use async/await.
   * @param {String} id
   * @return {Promise(CalendarItem)}
   */
  reminderByID: (id: string) => Promise<CalendarItem>

  /**
   * Note: Available from v3.0.25
   * Returns all events for today. Use `filter` to search for specific events (keyword in the title).
   * This function fetches events asynchronously, so use async/await. The returned promise contains an array of events.
   * @param {String?} filter
   * @return {Promise}
   */
  eventsToday: (filter?: string) => Promise<CalendarItem[]>

  /**
   * Note: Available from v3.0.25
   * Returns all reminders between for today. Use `filter` to search for specific reminders (keyword in the title).
   * This function fetches reminders asynchronously, so use async/await.
   * @param {String?} filter
   * @return {Promise}
   */
  remindersToday: (filter?: string) => Promise<CalendarItem[]>

  /**
   * Note: Available from v3.5.2
   * Returns all reminders (completed and incompleted) for the given lists (array of strings).
   * If you keep the lists variable empty, NotePlan will return all reminders from all lists. You can get all Reminders lists calling `Calendar.availableReminderListTitles()`
   * This function fetches reminders asynchronously, so use async/await.
   * @param {[String]?} lists
   * @return {Promise}
   */
  remindersByLists: (lists?: string[]) => Promise<CalendarItem[]>

  /**
   * Parses a text describing a text as natural language input into a date. Such as "today", "next week", "1st May", "at 5pm to 6pm", etc.
   * Returns and array of objects with possible results (usually one), the most likely at the top.
   * Access the dates in this array using ".start" and ".end". And access information of the matched date text using ".text" and ".index". You can see the full info by looking up "DateRangeObject" further below.
   * @param {String} text
   * @return {[DateRangeObject]}
   */
  parseDateText: (text: string) => DateRangeObject[]

  /**
   * Create a date object from parts. Like year could be 2021 as a number.
   * The month parameter starts with 1 = January, 2 = February...
   * @param {Int} year
   * @param {Int} month
   * @param {Int} day
   * @param {Int} hour
   * @param {Int} minute
   * @param {Int} second
   * @return {Date}
   */
  dateFrom: (
    year: number,
    month: number,
    day: number,
    hour: number,
    minute: number,
    second: number
  ) => Date

  /**
   * Add a unit to an existing date. Look up all unit types using `dateUnits`.
   * For example, to add 10 days, use num = 10 and type = "day"
   * @param {Date} date
   * @param {String} type
   * @param {Int} num
   * @return {Date}
   */
  addUnitToDate: (date: Date, type: string, num: number) => Date

  /**
   * Returns the integer of a unit like "year" (should be this year's number). Look up all unit types using `dateUnits`.
   * @param {Date} date
   * @param {String} type
   * @return {Int}
   */
  unitOf: (date: Date, type: string) => number

  /**
   * Returns a description of how much time has past between the date and today = now.
   * @param {Date} date
   * @return {String}
   */
  timeAgoSinceNow: (date: Date) => string

  /**
   * Returns the amount of units between the given date and now. Look up all unit types using `dateUnits`.
   * @param {Date} date
   * @param {String} type
   * @return {Int}
   */
  unitsUntilNow: (date: Date, type: string) => number

  /**
   * Returns the amount of units from now and the given date. Look up all unit types using `dateUnits`.
   * @param {Date} date
   * @param {String} type
   * @return {Int}
   */
  unitsAgoFromNow: (date: Date, type: string) => number

  /**
   * Returns the amount of units between the first and second date. Look up all unit types using `dateUnits`.
   * @param {Date} date1
   * @param {Date} date2
   * @param {String} type
   * @return {Int}
   */
  unitsBetween: (date1: Date, date2: Date, type: string) => number

  /**
   * Returns the week number of the given date adjusted by the start of the week configured by the user in the preferences.
   * @param {Date} date
   * @return {Int}
   */
  weekNumber: (date: Date) => number

  /**
   * Note: Available from v3.7
   * Returns the year number of the given date adjusted by the start of the week configured by the user in the preferences.
   * @param {Date} date
   * @return {Int}
   */
  weekYear: (date: Date) => number

  /**
   * Note: Available from v3.7
   * Returns the first day of the given date's week adjusted by the start of the week configured by the user in the preferences (means the returned date will always be the configured first day of the week).
   * @param {Date} date
   * @return {Date}
   */
  startOfWeek: (date: Date) => Date

  /**
   * Note: Available from v3.7
   * Returns the last day of the given date's week adjusted by the start of the week configured by the user in the preferences (means the returned endOfWeek date will always be the day before the first day of the week specified in Preferences).
   * @param {Date} date
   * @return {Date}
   */
  endOfWeek: (date: Date) => Date

  /**
   * Note: Available from v3.1
   * Get the titles of all calendars the user has access to. Set `writeOnly` true, if you want to get only the calendars the user has write access to (some calendars, like holidays are not writable).
   * @param {Boolean} writeOnly
   * @return {[String]}
   */
  availableCalendarTitles: (writeOnly: boolean) => string[]

  /**
   * Note: Available from v3.1
   * Get the titles of all reminders the user has access to.
   * @return {[String]}
   */
  availableReminderListTitles: () => string[]
}

const Calendar: CalendarType = {
  dateUnits: ['year', 'month', 'day', 'hour', 'minute', 'second'],

  add: (calendarItem: CalendarItem) => {
    return { id: '1', ...calendarItem }
  },

  update: async (calendarItem: CalendarItem) => {
    await Promise.resolve()
    console.log('Updated calendar item:', calendarItem)
  },

  remove: async (calendarItem: CalendarItem) => {
    await Promise.resolve()
    console.log('Removed calendar item:', calendarItem)
  },

  eventsBetween: async (_startDate: Date, _endDate: Date, _filter?: string) => {
    await Promise.resolve()
    return [{ id: '1', title: 'Mock Event', date: new Date() }]
  },

  remindersBetween: async (
    _startDate: Date,
    _endDate: Date,
    _filter?: string
  ) => {
    await Promise.resolve()
    return [{ id: '1', title: 'Mock Reminder', date: new Date() }]
  },

  eventByID: async (id: string) => {
    await Promise.resolve()
    return { id, title: 'Mock Event', date: new Date() }
  },

  reminderByID: async (id: string) => {
    await Promise.resolve()
    return { id, title: 'Mock Reminder', date: new Date() }
  },

  eventsToday: async (_filter?: string) => {
    await Promise.resolve()
    return [{ id: '1', title: 'Mock Today Event', date: new Date() }]
  },

  remindersToday: async (_filter?: string) => {
    await Promise.resolve()
    return [{ id: '1', title: 'Mock Today Reminder', date: new Date() }]
  },

  remindersByLists: async (_lists?: string[]) => {
    await Promise.resolve()
    return [{ id: '1', title: 'Mock List Reminder', date: new Date() }]
  },

  parseDateText: (_text: string) => {
    return [
      {
        start: new Date(),
        end: new Date(),
        text: 'mock date text',
        index: 0,
      },
    ]
  },

  dateFrom: (
    year: number,
    month: number,
    day: number,
    hour: number,
    minute: number,
    second: number
  ) => {
    return new Date(year, month - 1, day, hour, minute, second)
  },

  addUnitToDate: (date: Date, _type: string, _num: number) => {
    const newDate = new Date(date)
    return newDate
  },

  unitOf: (_date: Date, _type: string) => {
    return 0
  },

  timeAgoSinceNow: (_date: Date) => {
    return '1 day ago'
  },

  unitsUntilNow: (_date: Date, _type: string) => {
    return 1
  },

  unitsAgoFromNow: (_date: Date, _type: string) => {
    return 1
  },

  unitsBetween: (_date1: Date, _date2: Date, _type: string) => {
    return 1
  },

  weekNumber: (_date: Date) => {
    return 1
  },

  weekYear: (_date: Date) => {
    return 2024
  },

  startOfWeek: (_date: Date) => {
    return new Date()
  },

  endOfWeek: (_date: Date) => {
    return new Date()
  },

  availableCalendarTitles: (_writeOnly: boolean) => {
    return ['Calendar 1', 'Calendar 2']
  },

  availableReminderListTitles: () => {
    return ['Reminders 1', 'Reminders 2']
  },
}

declare global {
  interface Window {
    Calendar: CalendarType
  }
}

window.Calendar = Calendar

export default Calendar
