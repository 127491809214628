type NoteObject = {
  content: string
  title: string
  filename: string
  type: 'Notes' | 'Calendar'
  paragraphs: ParagraphObject[]
}

export type ParagraphObject = {
  type: string
  content: string
  lineIndex: number
  heading?: string
  indents?: number
  blockId?: string
}

type RangeObject = {
  start: number
  end: number
  length: number
}

type ThemeValues = {
  styles: {
    body: {
      color: string
      [key: string]: unknown
    }
    [key: string]: unknown
  }
  [key: string]: unknown
}

type ThemeObject = {
  name: string
  mode: 'dark' | 'light'
  filename: string
  values: ThemeValues
}

type EditorType = {
  /**
   * Get the note object of the opened note in the editor
   * @type {NoteObject}
   */
  note: NoteObject

  /**
   * Get or set the markdown text of the note (will be saved to file directly)
   * @type {String}
   */
  content: string

  /**
   * Get title of the note (first line)
   * @type {String}
   */
  title: string

  /**
   * Get the type of the note (indicates also where it is saved)
   * @type {"Notes" | "Calendar"}
   */
  type: 'Notes' | 'Calendar'

  /**
   * Get the filename of the note. This includes the relative folder.
   * @type {String}
   */
  filename: string

  /**
   * Get or set the array of paragraphs contained in this note
   * @type {[ParagraphObject]}
   */
  paragraphs: ParagraphObject[]

  /**
   * Get an array of selected lines
   * @type {[String]}
   */
  selectedLinesText: string[]

  /**
   * Get an array of selected paragraphs
   * @type {[ParagraphObject]}
   */
  selectedParagraphs: ParagraphObject[]

  /**
   * Get the raw selection range (hidden Markdown is considered)
   * @type {RangeObject}
   */
  selection: RangeObject

  /**
   * Get the rendered selection range (hidden Markdown is NOT considered)
   * @type {RangeObject}
   */
  renderedSelection: RangeObject

  /**
   * Get the selected text
   * @type {String}
   */
  selectedText: string

  /**
   * Get all supported themes
   * @type {[ThemeObject]}
   */
  availableThemes: ThemeObject[]

  /**
   * Get the current theme
   * @type {ThemeObject}
   */
  currentTheme: ThemeObject

  /**
   * Get the current system mode
   * @type {String}
   */
  currentSystemMode: 'dark' | 'light'

  /**
   * Prevents the next "Delete future todos" dialog
   * @type {Boolean}
   */
  skipNextRepeatDeletionCheck: boolean

  /**
   * Note: Available from NotePlan v3.2 (Mac Build: 662, iOS Build: 593)
   * Selects the full text in the editor.
   */
  selectAll: () => void

  /**
   * Note: Available from NotePlan v3.2 (Mac Build: 662, iOS Build: 593)
   * Copies the currently selected text in the editor to the system clipboard.
   */
  copySelection: () => void

  /**
   * Note: Available from NotePlan v3.2 (Mac Build: 662, iOS Build: 593)
   * Pastes the current content in the system clipboard into the current selection in the editor.
   */
  pasteClipboard: () => void

  /**
   * Inserts the given text at the given character position (index)
   * @param text - Text to insert
   * @param index - Position to insert at (you can get this using 'renderedSelection' for example)
   */
  insertTextAtCharacterIndex: (text: string, index: number) => void

  /**
   * Replaces the text at the given range with the given text
   * @param text - Text to insert
   * @param location - Position to insert at (you can get this using 'renderedSelection' for example)
   * @param length - Amount of characters to replace from the location
   */
  replaceTextInCharacterRange: (
    text: string,
    location: number,
    length: number
  ) => void

  /**
   * Inserts the given text at the current cursor position
   * @param text - Text to insert
   */
  insertTextAtCursor: (text: string) => void

  /**
   * Replaces the current cursor selection with the given text
   * @param text - Text to insert
   */
  replaceSelectionWithText: (text: string) => void

  /**
   * Opens a note using the given filename
   * @param filename - Filename of the note file (can be without extension), but has to include the relative folder such as `folder/filename.txt`.
   * @param newWindow - (optional) Open note in new window (default = false)?
   * @param highlightStart - (optional) Start position of text highlighting
   * @param highlightEnd - (optional) End position of text highlighting
   * @param splitView - (optional) Open note in a new split view (Note: Available from v3.4)
   * @param createIfNeeded - (optional) Create the note with the given filename if it doesn't exist (only project notes, v3.5.2+)
   * @param content - Content to fill the note (replaces contents if the note already existed) (v3.7.2+)
   */
  openNoteByFilename: (
    filename: string,
    newWindow?: boolean,
    highlightStart?: number,
    highlightEnd?: number,
    splitView?: boolean,
    createIfNeeded?: boolean,
    content?: string
  ) => Promise<NoteObject | null>

  /**
   * Opens a note by searching for the give title (first line of the note)
   */
  openNoteByTitle: (
    title: string,
    newWindow?: boolean,
    highlightStart?: number,
    highlightEnd?: number,
    splitView?: boolean
  ) => Promise<NoteObject | null>

  /**
   * Opens a note by searching for the give title (first line of the note)
   */
  openNoteByTitleCaseInsensitive: (
    title: string,
    newWindow?: boolean,
    caseSensitive?: boolean,
    highlightStart?: number,
    highlightEnd?: number,
    splitView?: boolean
  ) => Promise<NoteObject | null>

  /**
   * Opens a calendar note by the given date
   */
  openNoteByDate: (
    date: Date,
    newWindow?: boolean,
    highlightStart?: number,
    highlightEnd?: number,
    splitView?: boolean,
    timeframe?: 'week' | 'month' | 'quarter' | 'year'
  ) => Promise<NoteObject | null>

  /**
   * Opens a calendar note by the given date string
   */
  openNoteByDateString: (
    dateString: string,
    newWindow?: boolean,
    highlightStart?: number,
    highlightEnd?: number,
    splitView?: boolean
  ) => Promise<NoteObject | null>

  /**
   * Opens a weekly calendar note by the given year and week number
   */
  openWeeklyNote: (
    year: number,
    weeknumber: number,
    newWindow?: boolean,
    highlightStart?: number,
    highlightEnd?: number,
    splitView?: boolean
  ) => Promise<NoteObject | null>

  /**
   * (Raw) select text in the editor
   */
  select: (start: number, length: number) => void

  /**
   * (Rendered) select text in the editor
   */
  renderedSelect: (start: number, length: number) => void

  /**
   * Scrolls to and highlights the given paragraph
   */
  highlight: (paragraph: ParagraphObject) => void

  /**
   * Scrolls to and highlights the given character range
   */
  highlightByRange: (range: RangeObject) => void

  /**
   * Scrolls to and highlights the given range defined by the character index
   */
  highlightByIndex: (index: number, length: number) => void

  /**
   * Opens the print dialog for the current note
   */
  printNote: (withBacklinksAndEvents: boolean) => void
}

/**
 * Editor API
 * Methods will be injected by useSyncEditorToEditorAPI
 */
const Editor: EditorType = {
  note: {
    content: '# Sample Note\nThis is a mock note content',
    title: 'Sample Note',
    filename: 'notes/sample.txt',
    type: 'Notes',
    paragraphs: [
      {
        type: 'title',
        content: '# Sample Note',
        lineIndex: 0,
      },
      {
        type: 'text',
        content: 'This is a mock note content',
        lineIndex: 1,
      },
    ],
  },

  content: '# Sample Note\nThis is a mock note content',

  title: 'Sample Note',

  type: 'Notes',

  filename: 'notes/sample.txt',

  paragraphs: [
    {
      type: 'title',
      content: '# Sample Note',
      lineIndex: 0,
    },
    {
      type: 'text',
      content: 'This is a mock note content',
      lineIndex: 1,
    },
  ],

  selectedLinesText: ['This is a mock note content'],

  selectedParagraphs: [
    {
      type: 'text',
      content: 'This is a mock note content',
      lineIndex: 1,
    },
  ],

  selection: {
    start: 0,
    end: 10,
    length: 10,
  },

  renderedSelection: {
    start: 0,
    end: 10,
    length: 10,
  },

  selectedText: 'Sample text',

  availableThemes: [
    {
      name: 'Default Light',
      mode: 'light',
      filename: 'default-light.json',
      values: {
        styles: {
          body: {
            color: '#000000',
          },
        },
      },
    },
    {
      name: 'Default Dark',
      mode: 'dark',
      filename: 'default-dark.json',
      values: {
        styles: {
          body: {
            color: '#FFFFFF',
          },
        },
      },
    },
  ],

  currentTheme: {
    name: 'Default Light',
    mode: 'light',
    filename: 'default-light.json',
    values: {
      styles: {
        body: {
          color: '#000000',
        },
      },
    },
  },

  currentSystemMode: 'light',

  skipNextRepeatDeletionCheck: false,

  selectAll: () => {
    console.log('Select all text')
  },

  copySelection: () => {
    console.log('Copy selection to clipboard')
  },

  pasteClipboard: () => {
    console.log('Paste from clipboard')
  },

  insertTextAtCharacterIndex: (text: string, index: number) => {
    console.log(`Insert "${text}" at index ${index}`)
  },

  replaceTextInCharacterRange: (
    text: string,
    location: number,
    length: number
  ) => {
    console.log(`Replace ${length} chars at ${location} with "${text}"`)
  },

  insertTextAtCursor: (text: string) => {
    console.log(`Insert "${text}" at cursor`)
  },

  replaceSelectionWithText: (text: string) => {
    console.log(`Replace selection with "${text}"`)
  },

  openNoteByFilename: async (
    filename,
    newWindow,
    highlightStart,
    highlightEnd,
    splitView,
    createIfNeeded,
    content
  ) => {
    console.log(`Open note: ${filename}`)
    return null
  },

  openNoteByTitle: async (
    title,
    newWindow,
    highlightStart,
    highlightEnd,
    splitView
  ) => {
    console.log(`Open note by title: ${title}`)
    return null
  },

  openNoteByTitleCaseInsensitive: async (
    title,
    newWindow,
    caseSensitive,
    highlightStart,
    highlightEnd,
    splitView
  ) => {
    console.log(`Open note by title (case insensitive): ${title}`)
    return null
  },

  openNoteByDate: async (
    date,
    newWindow,
    highlightStart,
    highlightEnd,
    splitView,
    timeframe
  ) => {
    console.log(`Open note by date: ${date}`)
    return null
  },

  openNoteByDateString: async (
    dateString,
    newWindow,
    highlightStart,
    highlightEnd,
    splitView
  ) => {
    console.log(`Open note by date string: ${dateString}`)
    return null
  },

  openWeeklyNote: async (
    year,
    weeknumber,
    newWindow,
    highlightStart,
    highlightEnd,
    splitView
  ) => {
    console.log(`Open weekly note: ${year}-W${weeknumber}`)
    return null
  },

  select: (start: number, length: number) => {
    console.log(`Select ${length} chars from ${start}`)
  },

  renderedSelect: (start: number, length: number) => {
    console.log(`Rendered select ${length} chars from ${start}`)
  },

  highlight: (paragraph: ParagraphObject) => {
    console.log(`Highlight paragraph: ${paragraph.content}`)
  },

  highlightByRange: (range: RangeObject) => {
    console.log(`Highlight range: ${range.start}-${range.end}`)
  },

  highlightByIndex: (index: number, length: number) => {
    console.log(`Highlight ${length} chars at index ${index}`)
  },

  printNote: (withBacklinksAndEvents: boolean) => {
    console.log(`Print note with backlinks: ${withBacklinksAndEvents}`)
  },
}

declare global {
  interface Window {
    Editor: EditorType
  }
}

window.Editor = Editor

export default Editor
