import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

export default function SlideOver({ open, setOpen }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className='relative z-[60]' onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-in-out duration-500'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in-out duration-500'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-hidden '>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <Dialog.Panel className='pointer-events-auto relative w-screen max-w-md'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-500'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-500'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4'>
                      <button
                        type='button'
                        className='relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white'
                        onClick={() => setOpen(false)}
                      >
                        <span className='absolute -inset-2.5' />
                        <span className='sr-only'>Close panel</span>
                        <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className='flex h-full flex-col overflow-y-scroll bg-white dark:bg-gray-800 py-6 shadow-xl'>
                    <div className='px-4 sm:px-6'>
                      <Dialog.Title className='text-xl font-semibold leading-6 text-gray-900 dark:text-gray-300'>
                        Need help with Login / Signup ?
                      </Dialog.Title>
                    </div>
                    <div className='relative mt-6 flex-1 px-4 sm:px-6'>
                      {/* Your content */}
                      <iframe
                        src='https://www.loom.com/embed/8dd40dd8b5584c72a7daaeff9a741372?sid=e9468d9c-2ff5-4c15-aae2-dee58e7f5eb7'
                        frameBorder='0'
                        allowFullScreen
                        className='w-full h-56'
                      ></iframe>
                      {/* Show a collapsible FAQ section here */}
                      <div className='mt-6 dark:text-gray-200'>
                        <details className='group' open>
                          <summary className='cursor-pointer text-md font-bold opacity-70 dark:opacity-80'>
                            Which login should I use?
                          </summary>
                          <div className='mt-6 text-sm text-left'>
                            <p>Apple ID:</p>
                            <ul className='list-disc pl-4'>
                              <li>Syncs with native apps on macOS and iOS.</li>
                              <li>
                                Use this when you want to use it with the native
                                apps.
                              </li>
                              <li>
                                Use the same Apple ID you are logged in with
                                your devices.
                              </li>
                              <li>
                                Only the Apple ID is relevant, not the purchase
                                email (if purchased outside the App Store).
                              </li>
                              <li>
                                The web version is included in your Apple and{' '}
                                <a
                                  href='https://help.noteplan.co/article/235-how-to-login-into-the-web-version-using-setapp'
                                  target='_blank'
                                  rel='noreferrer noopener'
                                  className='underline'
                                >
                                  Setapp
                                </a>{' '}
                                subscription.
                              </li>
                              <li>
                                Use this if you are on{' '}
                                <a
                                  href='https://help.noteplan.co/article/240-how-to-access-noteplan-on-windows'
                                  target='_blank'
                                  rel='noreferrer noopener'
                                  className='underline'
                                >
                                  Windows
                                </a>{' '}
                                and use it with your iPhone, iPad or Mac.
                              </li>
                            </ul>
                          </div>
                          <div className='mt-6 mb-8 text-sm text-left'>
                            <p>Email:</p>
                            <ul className='list-disc pl-4'>
                              <li>
                                Does <b>not (yet)</b> sync with native apps.
                              </li>
                              <li>
                                Use this if you are not using Apple devices.
                              </li>
                            </ul>
                            <p className='mt-4'>
                              If you have questions, write us
                              (hello@noteplan.co).
                            </p>
                          </div>
                        </details>
                        <details className='group'>
                          <summary className='cursor-pointer text-md font-bold opacity-70 dark:opacity-80'>
                            Problems signing in?
                          </summary>
                          <div className='mt-6 text-sm text-left'>
                            <p>Apple ID:</p>
                            <ul className='list-disc pl-4'>
                              <li>
                                Turn off{' '}
                                <b>Advanced Data Protection in your </b>
                                macOS/iOS System Settings... &gt; Apple ID &gt;
                                iCloud (scroll down and turn off).
                              </li>
                              <li>
                                Turn off <b>browser extensions</b>. They
                                interfere with the Apple login.
                              </li>
                              <li>
                                Allow <b>third-party cookies</b> or
                                &quot;app.noteplan.co&quot; as exception.{' '}
                                <a
                                  className='underline'
                                  target='_blank'
                                  rel='noreferrer noopener'
                                  href='https://help.noteplan.co/article/222-cant-login-into-the-web-app-using-my-apple-id'
                                >
                                  Learn how
                                </a>
                                .
                              </li>
                              <li>
                                Turn off <b>ad blockers</b> or add
                                &quot;app.noteplan.co&quot; as exception.
                              </li>

                              <li>
                                Try a <b>different browser</b> (Safari or
                                Chrome) or open in{' '}
                                <b>private/incognito window</b> (if it works
                                here, it&apos;s most likely an extension).
                              </li>
                            </ul>
                          </div>
                          <div className='mt-6 mb-8 text-sm text-left'>
                            <p>Email:</p>
                            <ul className='list-disc pl-4'>
                              <li>
                                Refresh your email client and check the Spam
                                folder to see if you have received the one time
                                password.
                              </li>
                              <li>
                                Wait up to a minute to get the one time
                                password.
                              </li>
                              <li>
                                Try again in 1-2 minutes, if you have signed in
                                too often.
                              </li>
                            </ul>
                            <p className='mt-4'>
                              If you still have issues write us
                              (hello@noteplan.co).
                            </p>
                          </div>
                        </details>
                        <details className='group'>
                          <summary className='cursor-pointer text-md font-bold opacity-70 dark:opacity-80'>
                            Is my data encrypted?
                          </summary>
                          <div className='mt-6 text-sm text-left'>
                            <p>Apple ID:</p>
                            Your data is stored on CloudKit (iCloud).
                            Apple&apos;s iCloud servers{' '}
                            <a
                              href='https://developer.apple.com/documentation/cloudkit/encrypting_user_data'
                              target='_blank'
                              rel='noreferrer noopener'
                              className='text-blue-600 dark:text-blue-400 hover:underline'
                            >
                              use encryption
                            </a>{' '}
                            to ensure only authorized users can access data. We
                            are not using an additional layer of encryption. The
                            data is encrypted in transit by TLS (HTTPS).
                          </div>
                          <div className='mt-6 text-sm text-left'>
                            <p>Email:</p>
                            Your data is stored with a database provider
                            (Supabase) and is{' '}
                            <a
                              href='https://supabase.com/security'
                              target='_blank'
                              rel='noreferrer noopener'
                              className='text-blue-600 dark:text-blue-400 hover:underline'
                            >
                              encrypted at rest
                            </a>{' '}
                            by the provider, we are not using an additional
                            layer of encryption. It is encrypted in transit by
                            TLS (HTTPS).
                          </div>

                          <div className='mt-6 text-sm text-left'>
                            <p>Will you add E2EE?</p>
                            We are considering to add local encryption, so it
                            will be full E2EE instead of using the database
                            provided encryption (which slows down downloads).
                          </div>
                        </details>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
