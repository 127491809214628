import { Timeframe } from '../../utils/syncUtils'
import { SearchResultBlock } from '../editor/NoteReference'
import { CalendarSection } from './Search'
import dayjs, { Dayjs } from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import classNames from 'classnames'

dayjs.extend(advancedFormat)
dayjs.extend(RelativeTime)

type Props = {
  section: CalendarSection
  index: number
  handleRevealNote: (_recordName: string) => void
  keyword: string
}

function monthToString(month: number) {
  return dayjs().month(month).format('MMMM')
}

function monthsAgo(month: number, year: number) {
  return dayjs().month(month).year(year).fromNow()
}

function timeframeToTitle(timeframe: Timeframe, date: Dayjs): string {
  switch (timeframe) {
    case 'day': {
      return date.format('DD')
    }
    case 'week': {
      return `CW ${date.format('ww')}`
    }
    default: {
      return ''
    }
  }
}

function timeframeToSubtitle(timeframe: Timeframe, date: Dayjs): string {
  switch (timeframe) {
    case 'day': {
      return date.format('dddd')
    }
    case 'week': {
      return `${date.format('DD MMM')} - ${date.add(6, 'day').format('DD MMM (YYYY)')}`
    }
    default: {
      return ''
    }
  }
}

export default function CalendarSectionComponent({
  section,
  index,
  handleRevealNote,
  keyword,
}: Props) {
  const blockCount = section.months.reduce(
    (acc, month) =>
      acc +
      month.calendarResults.reduce(
        (acc, calendarResult) => acc + calendarResult.blocks.length,
        0
      ),
    0
  )
  return (
    <div key={index} className='p-3'>
      <h2
        className={classNames(
          'text-xs font-semibold uppercase text-zinc-500 dark:text-zinc-400 pl-3 py-0.5',
          section.teamSpaceId
            ? 'bg-green-200 dark:bg-green-950'
            : 'bg-zinc-100 dark:bg-zinc-900'
        )}
      >{`${section.title} (${blockCount.toString()})`}</h2>
      <div className='flex flex-col px-3'>
        {section.months.map((month, index) => (
          <div key={index} className='mt-12'>
            <div className='flex justify-between'>
              <h3 className='rounded-md bg-blue-100 px-2 py-0 leading-tight opacity-90 dark:bg-emerald-800'>
                {monthToString(month.month)}{' '}
                <span className='text-sm opacity-60'>{month.year}</span>
              </h3>
              <small className='text-xs opacity-50 dark:opacity-25'>
                {monthsAgo(month.month, month.year)}
              </small>
            </div>
            {month.calendarResults.map((calendarResult, index) => (
              <div key={index} className='mt-8'>
                {calendarResult.timeframe === 'week' ? (
                  <div className='flex items-start gap-1'>
                    <div className='flex items-baseline gap-1 rounded-md bg-orange-100 px-1 pt-1 opacity-80 dark:bg-zinc-700'>
                      <h3 className='text-base font-semibold leading-none'>
                        {timeframeToTitle(
                          calendarResult.timeframe,
                          calendarResult.date
                        )}
                      </h3>
                      <h4 className='text-xs font-semibold leading-none no-underline opacity-40 dark:opacity-50'>
                        {timeframeToSubtitle(
                          calendarResult.timeframe,
                          calendarResult.date
                        )}
                      </h4>
                    </div>
                    <div className='flex-grow border-t-2 border-zinc-100 py-1 dark:border-zinc-900'></div>
                  </div>
                ) : (
                  <div className='flex items-baseline gap-1 border-t-2 border-zinc-100 py-1 dark:border-zinc-900'>
                    <h3 className='text-lg font-semibold leading-none'>
                      {timeframeToTitle(
                        calendarResult.timeframe,
                        calendarResult.date
                      )}
                    </h3>
                    <h4 className='leadning-none text-xs font-semibold no-underline opacity-40'>
                      {timeframeToSubtitle(
                        calendarResult.timeframe,
                        calendarResult.date
                      )}
                    </h4>
                  </div>
                )}
                <div className='flex flex-col'>
                  {calendarResult.blocks.map((result, index) => (
                    <SearchResultBlock
                      key={index}
                      result={result}
                      onSelectNote={() => {
                        handleRevealNote(calendarResult.recordName)
                      }}
                      keyword={keyword}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}
