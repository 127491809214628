/* eslint-disable unicorn/filename-case */
import { useEffect } from 'react'
import { BlockNoteEditor } from '@packages/blocknote-core'
import Editor from '../plugins/api/Editor'

export function useSyncEditorToEditorAPI(editor: BlockNoteEditor | null) {
  useEffect(() => {
    if (!editor) return

    // Override the Editor API methods with TipTap implementations
    Editor.insertTextAtCursor = (text: string) => {
      const blocks = BlockNoteEditor.notePlanToBlocks(text, '')
      editor.insertBlocksAtCursor(blocks)
    }

    // Editor.insertTextAtCharacterIndex = (text: string, index: number) => {
    //   editor._tiptapEditor.commands.insertContentAt(index, text)
    // }

    // Editor.replaceSelectionWithText = (text: string) => {
    //   editor._tiptapEditor.commands.insertContent(text)
    // }

    // Editor.replaceTextInCharacterRange = (
    //   text: string,
    //   location: number,
    //   length: number
    // ) => {
    //   editor._tiptapEditor.commands.insertContentAt(
    //     { from: location, to: location + length },
    //     text
    //   )
    // }

    // Editor.selectAll = () => {
    //   editor._tiptapEditor.commands.selectAll()
    // }

    // Editor.select = (start: number, length: number) => {
    //   editor._tiptapEditor.commands.setTextSelection({
    //     from: start,
    //     to: start + length,
    //   })
    // }

    // Editor.renderedSelect = (start: number, length: number) => {
    //   editor._tiptapEditor.commands.setTextSelection({
    //     from: start,
    //     to: start + length,
    //   })
    // }
  }, [editor])
}
/* eslint-enable unicorn/filename-case */
