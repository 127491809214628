import { useMemo, useRef } from 'react'
import MiniSearch from 'minisearch'

export function useOptionsFilter(
  options: string[],
  searchTerm: string
): string[] {
  // Keep search index in a ref
  const indexReference = useRef<MiniSearch | null>(null)

  // Rebuild index only when options change
  useMemo(() => {
    const index = new MiniSearch({
      fields: ['text'],
      storeFields: ['text'],
      searchOptions: {
        fuzzy: 0.2,
        prefix: true,
      },
    })

    // Add all options to index
    const documents = options.map((text, id) => ({ id, text }))
    index.addAll(documents)
    indexReference.current = index
  }, [options])

  // Early return if search term is too short
  if (searchTerm.length <= 2) {
    return options
  }

  // Search using the cached index
  const results = indexReference.current?.search(searchTerm) ?? []
  return results.map((result) => options[result.id as number])
}
